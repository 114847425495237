import { Button, Col, Form, Input, Select, Table } from "antd";
import { inject, observer } from "mobx-react";
import { CloseOutlined, SaveOutlined } from "@ant-design/icons";
import { Field, Formik, FormikHelpers, FormikProps } from "formik";
import React from "react";
import { RouteComponentProps } from "react-router";
import { DictionaryStore } from "dictionary/dictionaryStore";
import { PageHeader } from "layout/PageHeader";
import PrimaryCard from "../../layout/PrimaryCard";
import { ContainerAmountDto, ContainerClient, CreateExciseRegisterStateCmd, ExciseRegisterClient, ExciseRegisterStateClient } from "services/GeneratedClient";
import { FCenteredRow, FDatePicker, FFieldLabel, FInputNumber } from "forms/FormikFormItems";
import { ajaxByUser, ajaxCatch } from "helper/api";
import ExciseRegisterStateValidation from "./ExciseRegisterStateValidation";
import { RegisterStore } from "register/RegisterStore";
import { Moment } from 'moment';

interface IProps extends RouteComponentProps<any> {
    dictionaryStore?: DictionaryStore;
    registerStore?: RegisterStore;
    exciseRegisterId: number;
}

export class ExciseRegisterStateForm {
    date!: Moment;
    containers!: ContainerAmountDto[]
}
interface IState {
    isLoading: boolean;
    form?: ExciseRegisterStateForm;
    hasRegisterControlOfNegativeStates: boolean;
}

@inject("dictionaryStore")
@inject("registerStore")
@observer
export default class ExciseRegisterStateCreate extends React.PureComponent<IProps, IState> {
    private readonly goBackUrl = `/Admin/ExciseRegisters`;
    private validation: ExciseRegisterStateValidation;

    constructor(props: any) {
        super(props);

        this.state = {
            isLoading: false,
            hasRegisterControlOfNegativeStates: true,
        };

        this.validation = new ExciseRegisterStateValidation();
    }

    public componentDidMount() {
        this.getInitialValues(this.props.exciseRegisterId);
        this.hasControlOfNegativeStates(this.props.exciseRegisterId);
    }

    private getInitialValues = (registerId: number) => {
        ajaxCatch(() =>
            new ContainerClient().getContainersByExciseRegister(registerId)
                .then((resp: ContainerAmountDto[] | null) => {
                    var form = new ExciseRegisterStateForm()
                    form.containers = resp!;
                    this.setState({
                        isLoading: false,
                        form: form
                    })
                })
        )
    }

    hasControlOfNegativeStates = (registerId: number) => {
        ajaxCatch(() =>
            new ExciseRegisterClient().hasRegisterControlOfNegativeStates(registerId),
            (response: boolean) => {
                this.setState({
                    hasRegisterControlOfNegativeStates: response
                });
            });
    }

    public send(form: ExciseRegisterStateForm, actions: FormikHelpers<ExciseRegisterStateForm>) {
        this.setState({ isLoading: true })

        let cmd = new CreateExciseRegisterStateCmd({
            registerId: this.props.exciseRegisterId,
            date: form.date.asUtc(),
            exciseRegisterContainerStateDto: form.containers
        });

        ajaxByUser(`Zainicjalizowano ${this.state.hasRegisterControlOfNegativeStates? "stan początkowy" : "rozchód narastająco"}`,
            () => new ExciseRegisterStateClient().createInitialState(cmd)
                .then((resp: any) => {
                    actions.setSubmitting(false);
                    this.props.history.push(this.goBackUrl);
                }).finally(() => this.setState({ isLoading: false })));
    }

    private countSum(values: ExciseRegisterStateForm) {
        return (values.containers.filter(x => x.amount != undefined).length > 0) ?
            values.containers.filter(x => x.amount != undefined).map(x => x.amount).reduce((a, c) => {
                return a! + c! ?? 0
            }) : 0
    }

    private getPageHeaderTitle(): string {
        return this.state.hasRegisterControlOfNegativeStates
            ? "INICJALIZOWANIE STANU POCZĄTKOWEGO EWIDENCJI AKCYZOWEJ"
            : "ROZCHÓD NARASTAJĄCO NA DZIEŃ INICJALIZACJI EWIDENCJI AKCYZOWEJ"
    }

    public render() {
        return (
            <>
                {this.state.form?.containers && (
                    <>
                        <PageHeader title={this.getPageHeaderTitle()} />
                        <Formik<ExciseRegisterStateForm>
                            initialValues={this.state.form}
                            enableReinitialize
                            onSubmit={(values, actions) => {
                                this.send(values, actions);
                            }}
                            validationSchema={this.validation.schema}
                        >
                            {(props: FormikProps<ExciseRegisterStateForm>) => (
                                <PrimaryCard>
                                    <FCenteredRow>
                                        <Col sm={3}>
                                            <FFieldLabel label="Data" oneRow />
                                            <Field component={FDatePicker} name="date" />
                                        </Col>

                                    </FCenteredRow>
                                    <FCenteredRow>
                                        <Form>

                                            <Table

                                                dataSource={this.state.form!.containers!}
                                                rowKey={(row) => row.containerId}
                                                size="small"
                                                pagination={false}
                                                style={{ width: 600 }}
                                                columns={[
                                                    {
                                                        dataIndex: "containerId",
                                                        key: "ContainerId",
                                                        title: "Id",
                                                    },
                                                    {
                                                        dataIndex: "containerName",
                                                        key: "ContainerName",
                                                        title: "Zbiornik",
                                                    },
                                                    {
                                                        dataIndex: "containers.amount",
                                                        key: "Amount",
                                                        title: "Ilość",
                                                        render: (text, record, i) => {
                                                            return <Field component={FInputNumber} precision={3} name={`containers.${i}.amount`} />
                                                        },
                                                    },
                                                ]}
                                                summary={() => (
                                                    <Table.Summary fixed>
                                                        <Table.Summary.Row>
                                                            <Table.Summary.Cell index={0} colSpan={2}>Suma</Table.Summary.Cell>
                                                            <Table.Summary.Cell index={1}>{this.countSum(props.values)?.toFixed(3)}
                                                            </Table.Summary.Cell>
                                                        </Table.Summary.Row>
                                                    </Table.Summary>
                                                )}
                                            />
                                        </Form>
                                    </FCenteredRow>
                                    <FCenteredRow className="bottom-action-buttons" style={{ marginTop: "10px" }}>
                                        <Button type="primary" icon={<CloseOutlined />} size="large" onClick={() => {
                                            this.props.history.push(this.goBackUrl);
                                        }}>Anuluj</Button>
                                        <Button type="primary" icon={<SaveOutlined />} size="large" loading={this.state.isLoading} onClick={() => {
                                            props.submitForm();
                                        }}>Zapisz</Button>
                                    </FCenteredRow>
                                </PrimaryCard>
                            )}
                        </Formik>
                    </>
                )}
            </>
        )
    }
}
