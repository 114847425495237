import { InboxOutlined } from '@ant-design/icons';
import { Col, Input, InputNumber, Row, Select, Popover, AutoComplete, AutoCompleteProps, Tooltip, Checkbox, CheckboxProps } from "antd";
import { FieldProps, getIn, FormikProps, FormikErrors, Field } from "formik";
import * as React from "react";
import { InputNumberProps } from "antd/lib/input-number";
import { InputProps } from "antd/lib/input";
import { setLocale } from "yup";
import { ColProps } from "antd/lib/col";
import { RowProps } from "antd/lib/row";
import Collapse, { CollapsePanelProps, CollapseProps } from "antd/lib/collapse";
import { SelectProps } from "antd/lib/select";
import { InputHTMLAttributes } from "react";
import { DictionaryValueDto, DictionaryName } from "../services/GeneratedClient";
import { inject, observer } from "mobx-react";
import { DictionaryStore } from "../dictionary/dictionaryStore";
import FadingTooltip from "../layout/FadingTooltip"
import { DraggerProps, RcFile } from "antd/lib/upload";
import Dragger from "antd/lib/upload/Dragger";
import { Moment } from 'moment'
import { DatePicker, DatePickerProps } from 'components/DatePicker';
import { TimePicker, TimePickerProps } from 'components/TimePicker';
import {
    TransferItem,
    TransferProps as $TransferProps,
} from 'antd/lib/transfer'
import { Transfer as $Transfer } from 'antd'
import * as moment from 'antd/node_modules/moment';
//https://github.com/jquense/yup/blob/master/src/locale.js
setLocale({
    mixed: {
        default: 'Wartość jest nieprawidłowa',
        required: 'Pole jest wymagane',
        notType: ({ path, type, value, originalValue }) => 'Wartość jest nieprawidłowa'
    },
    array: {
        min: "Minimalna ilość elementów to ${min}",
        max: "Maksymalna ilość elementów to ${max}"
    },
    number: {
        min: 'Minimalna wartość musi być większa lub równa ${min}',
        max: 'Maksymalna wartość musi być mniejsza lub równa ${max}',
        lessThan: 'Wartość musi być mniejsza niż ${less}',
        moreThan: 'Wartość musi być większa niż ${more}',
        integer: 'Wartość musi być liczbą całkowitą',
    },
    string: {
        min: 'Minimalna długość to ${min}',
        max: 'Maksymalna długość do ${max}',
        matches: 'Wartość musi być zgodna z formatem "${regex}"',
        email: 'Nieprawidłowy adres email',
        length: 'Wymagana długość to ${length}'
    }
});

function isNotValidAndTouched(props: FieldProps): boolean {
    return isNotValidAndTouchedField(props.form, props.field.name);
}

function isNotValidAndTouchedField(form: FormikProps<any>, fieldName: string): boolean {
    let getInError = getIn(form.errors, fieldName);
    let getInTouched = getIn(form.touched, fieldName);

    return (getInError && (getInTouched || form.submitCount > 0))
}

function shouldShowErrorMessage(form: FormikProps<any>, fieldName: string): boolean {
    let notValid = isNotValidAndTouchedField(form, fieldName);
    // if (notValid) {
    //     let getInError = getIn(form.errors, fieldName);

    //     if (getInError) {
    //         let error = getInError.toString();
    //         //  if (error.includes("required") || error.endsWith("wymagane")) return false;
    //     }
    // }

    return notValid;
}

export class FErrorMessage extends React.Component<{ errors: FormikErrors<any>, name: string }>{
    public render() {
        return <div className={"form-error-label"} style={{ whiteSpace: "pre-wrap" }}>
            {getIn(this.props.errors, this.props.name)}
        </div>
    }
}

export class FArrayErrorMessage extends React.Component<{ message: any }>{
    public render() {
        return typeof this.props.message === 'string' ?
            <div className="form-error form-error-label">
                {this.props.message}
            </div> : null
    }
}

export class FDirectErrorMessage extends React.Component<{ message: any }>{
    public render() {
        return this.props.message !== undefined ? <div className="form-error form-error-label">
            {this.props.message}
        </div> : null
    }
}

export interface IFFieldLabelProps {
    label: string;
    optional?: boolean;
    oneRow?: boolean;
    toolTip?: string;
}

export class FLabel extends React.Component<IFFieldLabelProps & ColProps> {
    public render() {
        return <>
            <FadingTooltip title={this.props.toolTip} >
                <Col sm={this.props.sm} style={this.props.oneRow ? { float: "left" } : { maxWidth: "100%" }}>
                    <label className="form-label">{this.props.label}</label>
                </Col>
            </FadingTooltip>
        </>
    }
}

export class FFieldLabel extends React.Component<IFFieldLabelProps & ColProps> {
    static defaultProps = {
        sm: 6
    }
    public render() {
        return <>
            <FadingTooltip title={this.props.toolTip} >
                <Col sm={this.props.sm} style={this.props.oneRow ? { float: "left" } : { maxWidth: "100%" }}>
                    {this.props.optional && <label className="form-label-optional">{this.props.label} {(" (opcjonalne)")}</label>}
                    {(this.props.optional === undefined || this.props.optional === false) &&
                        <label className="form-label">{this.props.label}</label>}
                </Col>
            </FadingTooltip>
        </>
    }
}

export class OptionalFieldLabel extends React.Component<IFFieldLabelProps & ColProps> {
    static defaultProps = {
        sm: 6
    }
    public render() {
        return <>
            <FadingTooltip title={this.props.toolTip} >
                <Col sm={this.props.sm} style={this.props.oneRow ? { float: "left" } : {}}>
                    {this.props.optional && <label className="form-label-optional">{this.props.label}</label>}
                    {(this.props.optional === undefined || this.props.optional === false) &&
                        <label className="form-label">{this.props.label}</label>}
                </Col>
            </FadingTooltip>
        </>
    }
}

export interface FormikFieldProps {
    name: string
    validate?: (value: any) => undefined | string | Promise<any>
    fast?: boolean
}

export class FRow extends React.Component<RowProps> {
    static defaultProps = {
        gutter: 16
    }
    public render() {
        return <Row {...this.props}>{this.props.children}</Row>
    }
}

export class FCenteredRow extends React.Component<RowProps> {
    static defaultProps = {
        gutter: 16
    }
    public render() {
        return <Row className={"centered-row"} justify="center" {...this.props}>{this.props.children}</Row>
    }
}

export interface IFormikInputProps extends FieldProps<any> {
    onChangeAttempt?: (newValue: string) => void;
}

export class FInput extends React.Component<IFormikInputProps & InputProps & InputHTMLAttributes<Input>, {}> {
    public render() {
        const input = () => {
            return <Input {...this.props.field} disabled={this.props.disabled} maxLength={this.props.maxLength} autoFocus={this.props.autoFocus} type="text" onChange={(v: any) => {
                this.props.form.handleChange(v);
                if (this.props.onChangeAttempt !== undefined)
                    this.props.onChangeAttempt(v);
            }} />
        }
        return <>
            <div className={isNotValidAndTouched(this.props) ? "form-error" : ""}>
                {shouldShowErrorMessage(this.props.form, this.props.field.name) ?
                    <Popover placement="left" content={<FErrorMessage errors={this.props.form.errors} name={this.props.field.name} />}>
                        {input()}
                    </Popover>
                    : input()
                }
            </div>
        </>
    }
}

export class FInputNumber extends React.Component<IFormikInputProps & InputNumberProps, {}> {
    static defaultProps = {
        precision: 2,
        min: 0,
        decimalSeparator: ","
    }

    public render() {
        const input = () => {
            return <InputNumber {...this.props} {...this.props.field}
                onChange={(v: number | string) => {
                    if (v != null) {
                        this.props.form.setFieldValue(this.props.field.name, v)
                    }
                    else
                        this.props.form.setFieldValue(this.props.field.name, undefined)
                }} />
        }

        return <>
            <div className={isNotValidAndTouched(this.props) ? "form-error" : ""}>
                {shouldShowErrorMessage(this.props.form, this.props.field.name) ?
                    <Popover placement="left" content={<FErrorMessage errors={this.props.form.errors} name={this.props.field.name} />}>
                        {input()}
                    </Popover>
                    : input()
                }

            </div>
        </>
    }
}

/**
 * Kontrolka do obsługi wartości które są datami.
 * Nie nadaje się do obsługi kolumny zawierającej datę i czas. Nie będzie działać jak będzie dla jednej wartości FDatePicker i FTimePicker.
 * Po podpięciu wartości do tego pola, czas zostanie z niego usunięty. Zawsze będzie "00:00:00".
 * Na razie nie ma takiego przypadku. Jak będzie to najlepiej zrobić nową kontrolkę FDateTimePicker.
 */
export class FDatePicker extends React.Component<IFormikInputProps & DatePickerProps, {}> {
    private removeTime(dateTime: any) {
        return (dateTime as Moment)?.asDate().asUtc();
    }

    public render() {
        const input = () => {
            return <DatePicker value={this.removeTime(this.props.field.value)} {...this.props}
                allowClear={true}
                onChange={(v: Moment | null) => {
                    if (v === null || v === undefined) {
                        this.props.form.setFieldValue(this.props.field.name, undefined);
                    } else {
                        this.props.form.setFieldValue(this.props.field.name, this.removeTime(v));
                    }
                    setTimeout(() => this.props.form.setFieldTouched(this.props.field.name, true));
                }}
            />
        }

        return <>
            <div className={isNotValidAndTouched(this.props) ? "form-error" : ""}>
                {shouldShowErrorMessage(this.props.form, this.props.field.name) ?
                    <Popover placement="left" content={<FErrorMessage {...this.props.form} name={this.props.field.name} />}>
                        {input()}
                    </Popover>
                    : input()
                }
            </div>
        </>
    }

}

export class FTimePicker extends React.Component<IFormikInputProps, TimePickerProps, {}> {
    private setLocal(dateTime: any) {
        return (dateTime as Moment)?.local();
    }

    public render() {
        const input = () => {
            return <TimePicker value={this.setLocal(this.props.field.value)}
                onOpenChange={(o) => {
                    if (!o) {
                        this.props.form.setFieldTouched(this.props.field.name, true);
                    }
                }}
                onChange={(v: Moment | null) => {
                    if (v === null || v === undefined) {
                        this.props.form.setFieldValue(this.props.field.name, undefined);
                    } else {
                        this.props.form.setFieldValue(this.props.field.name, this.setLocal(v));
                    }
                    setTimeout(() => this.props.form.setFieldTouched(this.props.field.name, true));
                }} />
        }

        return <>
            <div className={isNotValidAndTouched(this.props) ? "form-error" : ""}>
                {shouldShowErrorMessage(this.props.form, this.props.field.name) ?
                    <Popover placement="left" content={<FErrorMessage {...this.props.form} name={this.props.field.name} />}>
                        <div>{input()}</div>
                    </Popover>
                    : input()
                }
            </div>
        </>
    }
}

export interface IFSelectProps extends FieldProps<any> {
    isBoolean?: boolean;
}

export class FSelect extends React.Component<IFSelectProps & IFormikInputProps & SelectProps<any>, {}> {
    public render() {
        const {isBoolean, ...newProps} = this.props;
        const input = () => {
            return <Select allowClear={true} placeholder="Wybierz" value={isBoolean ? this.props.field.value?.toString() : this.props.field.value} {...newProps} disabled={this.props.disabled}
                onBlur={() => {
                    this.props.form.setFieldTouched(this.props.field.name, true);
                }}
                onSelect={() => {
                }}
                onChange={(v: any, o: any) => {
                    if (v === undefined) v = "";
                    this.props.form.setFieldValue(this.props.field.name, v);
                    setTimeout(() => this.props.form.setFieldTouched(this.props.field.name, true));

                    if (this.props.onChange) this.props.onChange(v, o);
                }}
            >
                {this.props.children}
            </Select>
        }

        return <>
            <div className={isNotValidAndTouched(this.props) ? "form-error" : ""}>
                {shouldShowErrorMessage(this.props.form, this.props.field.name) ?
                    <Popover placement="left" content={<FErrorMessage {...this.props.form} name={this.props.field.name} />}>
                        {input()}
                    </Popover>
                    : input()
                }
            </div>
        </>
    }
}
export class FSelectDic extends React.Component<IFSelectProps & IFormikInputProps & SelectProps<any>, {}> {
    public render() {
        const input = () => {
            return <Select allowClear={true} placeholder="Wybierz" value={this.props.field.value}  {...this.props} disabled={this.props.disabled}
                onBlur={() => {
                    this.props.form.setFieldTouched(this.props.field.name, true);
                }}
                onSelect={() => {
                }}

                onChange={(v: any, o: any) => {
                    if (v === undefined) v = "";
                    this.props.form.setFieldValue(this.props.field.name, v);
                    setTimeout(() => this.props.form.setFieldTouched(this.props.field.name, true));

                    if (this.props.onChange) this.props.onChange(v, o);
                }}
            >
                {this.props.children}
            </Select>
        }

        return <>
            <div className={isNotValidAndTouched(this.props) ? "form-error" : ""}>
                {shouldShowErrorMessage(this.props.form, this.props.field.name) ?
                    <Popover placement="left" content={<FErrorMessage {...this.props.form} name={this.props.field.name} />}>
                        {input()}
                    </Popover>
                    : input()
                }
            </div>
        </>
    }
}

export interface IFormikDictionarySelectProps extends FieldProps<any> {
    dictionaryValues?: DictionaryValueDto[] | null | undefined; // jeśli podano listę wartości to nazwa słownika jest ignorowana (używać do filtrowania słownika na formatkach)
    dictionaryName?: DictionaryName | undefined;

    dictionaryStore?: DictionaryStore;
    valueToLowerCase?: boolean | undefined;
    format?: "value" | "displayValue" | "valueAndDisplayValue"
}

@inject("dictionaryStore")
@observer
export class FDictionarySelect extends React.Component<SelectProps<DictionaryValueDto> & IFormikDictionarySelectProps, {}> {
    getDictionaryValuesForOptions() {
        let dictionaryValues: DictionaryValueDto[];

        if (this.props.dictionaryValues === undefined) {
            if (this.props.dictionaryName === undefined) throw "Nie podano nazwy słownika ani listy możliwych wartości";

            dictionaryValues = this.props.dictionaryStore!.getDictionary(this.props.dictionaryName!).values!;
        } else {
            dictionaryValues = this.props.dictionaryValues!;
        }

        return dictionaryValues;
    }

    public render() {
        let values: DictionaryValueDto[] = this.getDictionaryValuesForOptions();

        const { dictionaryValues, dictionaryStore, dictionaryName, ...props } = this.props;
        return <FSelect {...props} showSearch optionFilterProp="children" value={Array.isArray(this.props.field.value) ? this.props.field.value : this.props.field.value?.toString()} >
            {values && values!.map(x =>
                <Select.Option key={x.value} value={this.props.valueToLowerCase === true ? x.value!.toLowerCase() : x.value || ""}>
                    {(!this.props.format || this.props.format == "displayValue") && x.displayValue}
                    {(this.props.format && this.props.format == "value") && x.value}
                    {(this.props.format && this.props.format == "valueAndDisplayValue") && `${x.value} - ${x.displayValue}`}
                </Select.Option>)
            }
        </FSelect>
    }
}

@inject("dictionaryStore")
@observer
export class FDictionarySelectWithTooltip extends React.Component<SelectProps<DictionaryValueDto> & IFormikDictionarySelectProps, {}> {
    getDictionaryValuesForOptions() {
        let dictionaryValues: DictionaryValueDto[];

        if (this.props.dictionaryValues === undefined) {
            if (this.props.dictionaryName === undefined) throw "Nie podano nazwy słownika ani listy możliwych wartości";

            dictionaryValues = this.props.dictionaryStore!.getDictionary(this.props.dictionaryName!).values!;
        } else {
            dictionaryValues = this.props.dictionaryValues!;
        }

        return dictionaryValues;
    }

    public render() {
        let values: DictionaryValueDto[] = this.getDictionaryValuesForOptions();
        const { dictionaryValues, dictionaryStore, dictionaryName, ...props } = this.props;
        return <FSelect {...props} showSearch optionFilterProp="children"
            filterOption={(input, option) => {
                const optionDisplayValue = values.find(x => x.value == option!.value)?.displayValue;
                if (!optionDisplayValue) {
                    return false;
                }
                return optionDisplayValue.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
            value={Array.isArray(this.props.field.value) ? this.props.field.value : this.props.field.value?.toString()}>
            {values && values!.map(x =>
                <Select.Option key={x.value} value={this.props.valueToLowerCase === true ? x.value!.toLowerCase() : x.value || ""}>
                    <Tooltip title={x.displayValue} placement="left">
                        {(!this.props.format || this.props.format == "displayValue") && x.displayValue}
                        {(this.props.format && this.props.format == "value") && x.value}
                        {(this.props.format && this.props.format == "valueAndDisplayValue") && `${x.value} - ${x.displayValue}`}
                    </Tooltip>
                </Select.Option>)
            }
        </FSelect>
    }
}

export interface IFAutoCompleteProps extends FieldProps<any> {
}

export class FAutoComplete extends React.Component<IFAutoCompleteProps & AutoCompleteProps, {}> {
    public render() {
        const input = () => {
            return <AutoComplete allowClear={true} placeholder="Podaj wartość lub wybierz" value={this.props.field.value} {...this.props} disabled={this.props.disabled}
                onBlur={() => {
                    this.props.form.setFieldTouched(this.props.field.name, true);
                }}
                onSelect={() => {
                }}
                onChange={(v: any, o: any) => {
                    if (v === undefined) {
                        setTimeout(() => this.props.form.setFieldTouched(this.props.field.name, true));
                        v = "";
                    }
                    this.props.form.setFieldValue(this.props.field.name, v);

                    if (this.props.onChange) this.props.onChange(v, o);
                }}
                filterOption={(inputValue, option) =>
                    option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                }
            >
                {this.props.children}
            </AutoComplete>
        }

        return <>
            <div className={isNotValidAndTouched(this.props) ? "form-error" : ""}>
                {shouldShowErrorMessage(this.props.form, this.props.field.name) ?
                    <Popover placement="left" content={<FErrorMessage {...this.props.form} name={this.props.field.name} />}>
                        {input()}
                    </Popover>
                    : input()
                }
            </div>
        </>
    }
}

@inject("dictionaryStore")
@observer
export class FDictionaryAutoComplete extends React.Component<AutoCompleteProps & IFormikDictionarySelectProps, {}> {
    getDictionaryValuesForOptions() {
        let dictionaryValues: DictionaryValueDto[];

        if (this.props.dictionaryValues === undefined) {
            if (this.props.dictionaryName === undefined) throw "Nie podano nazwy słownika ani listy możliwych wartości";

            dictionaryValues = this.props.dictionaryStore!.getDictionary(this.props.dictionaryName!).values!;
        } else {
            dictionaryValues = this.props.dictionaryValues!;
        }

        return dictionaryValues;
    }

    public render() {
        let values: DictionaryValueDto[] = this.getDictionaryValuesForOptions();

        const { dictionaryValues, ...props } = this.props;
        return <FAutoComplete {...props} showSearch optionFilterProp="children" value={Array.isArray(this.props.field.value) ? this.props.field.value : this.props.field.value?.toString()} >
            {values && values!.map(x =>
                <AutoComplete.Option key={x.value} value={this.props.valueToLowerCase === true ? x.value!.toLowerCase() : x.value || ""}>
                    {(!this.props.format || this.props.format == "displayValue") && x.displayValue}
                    {(this.props.format && this.props.format == "value") && x.value}
                    {(this.props.format && this.props.format == "valueAndDisplayValue") && `${x.value} - ${x.displayValue}`}
                </AutoComplete.Option>)
            }
        </FAutoComplete>
    }
}

@inject("dictionaryStore")
@observer
export class FDictionaryAutoCompleteWithTooltip extends React.Component<AutoCompleteProps & IFormikDictionarySelectProps, {}> {
    getDictionaryValuesForOptions() {
        let dictionaryValues: DictionaryValueDto[];

        if (this.props.dictionaryValues === undefined) {
            if (this.props.dictionaryName === undefined) throw "Nie podano nazwy słownika ani listy możliwych wartości";

            dictionaryValues = this.props.dictionaryStore!.getDictionary(this.props.dictionaryName!).values!;
        } else {
            dictionaryValues = this.props.dictionaryValues!;
        }

        return dictionaryValues;
    }

    public render() {
        let values: DictionaryValueDto[] = this.getDictionaryValuesForOptions();

        const { dictionaryValues, dictionaryStore, dictionaryName, ...props } = this.props;
        return <FAutoComplete {...props} showSearch optionFilterProp="children" value={Array.isArray(this.props.field.value) ? this.props.field.value : this.props.field.value?.toString()} >
            {values && values!.map(x =>
                <AutoComplete.Option key={x.value} value={this.props.valueToLowerCase === true ? x.value!.toLowerCase() : x.value || ""}>
                    <Tooltip title={x.displayValue} placement="left">
                        {(!this.props.format || this.props.format == "displayValue") && x.displayValue}
                        {(this.props.format && this.props.format == "value") && x.value}
                        {(this.props.format && this.props.format == "valueAndDisplayValue") && `${x.value} - ${x.displayValue}`}
                    </Tooltip>
                </AutoComplete.Option>)
            }
        </FAutoComplete>
    }
}

export interface IFormikDraggerProps extends FieldProps<any> {
    draggerDescription?: string
}

export class FDragger extends React.Component<DraggerProps & IFormikDraggerProps, {}> {
    public render() {
        const input = () => {
            return (
                <Dragger {...this.props} beforeUpload={(file: RcFile, FileList: RcFile[]) => {
                    this.props.beforeUpload && this.props.beforeUpload(file, FileList);
                    this.props.form.setFieldValue(this.props.field.name, this.props.multiple ? FileList : file);
                    setTimeout(() => this.props.form.setFieldTouched(this.props.field.name, true));

                    return false;
                }}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    {this.props.draggerDescription && <p className="ant-upload-text" children={this.props.draggerDescription}></p>}
                </Dragger>
            );
        }
        return <>
            <div className={isNotValidAndTouched(this.props) ? "form-error" : ""}>
                {shouldShowErrorMessage(this.props.form, this.props.field.name) ?
                    <>
                        {input()}
                        <FErrorMessage {...this.props.form} name={this.props.field.name} />
                    </>
                    : input()
                }
            </div>
        </>
    }
}


export class FDictionaryLanguageSelect extends React.Component<SelectProps<DictionaryValueDto> & IFormikDictionarySelectProps, {}> {
    public render() {
        return <FDictionarySelect {...this.props} valueToLowerCase={true} dictionaryName={DictionaryName.LanguageCode} />
    }
}




export class FCollapse extends React.Component<CollapseProps> {
    public render() {

        return <Collapse {...this.props} className={"form-collapse"}>
            {this.props.children}
        </Collapse>
    }
}

interface IFPanelProps {
    sectionError?: string | undefined;
    formikProps?: FormikProps<any>;
    fieldName?: string | undefined;
}

export class FPanel extends React.Component<CollapsePanelProps & IFPanelProps> {
    public render() {
        let sholudShowError = this.props.sectionError !== undefined;

        if (this.props.fieldName && this.props.formikProps && getIn(this.props.formikProps.errors, this.props.fieldName) && this.props.formikProps.submitCount > 0)
            sholudShowError = true;

        return <Collapse.Panel {...this.props} className={sholudShowError ? "form-panel-error" : ""}>
            {this.props.children}
        </Collapse.Panel>
    }
}

export function isFieldInArrayError(props: any, errors: any, index: number, fieldPatterns: string[]) {
    return props && errors && errors[index] && isFieldError(
        {
            errors: errors[index],
            submitCount: props.submitCount
        },
        fieldPatterns);
}

export function isFieldError(props: any, fieldPatterns: string[]) {
    for (let error in props.errors)
        for (let i = 0; i < fieldPatterns.length; i++) {
            let fieldName = fieldPatterns[i];

            if (fieldPatterns[i].endsWith("*")) {
                fieldName = fieldPatterns[i].slice(0, fieldPatterns[i].length - 1);

                if (error.startsWith(fieldName) && props.submitCount) return true;
            } else {
                if (error === fieldPatterns[i] && props.submitCount) return true;
            }
        }

    return false;
}

interface IFCheckbox {
    label?: string;
}

export class FCheckbox extends React.Component<
    IFormikInputProps & CheckboxProps & IFCheckbox
> {
    constructor(props: IFormikInputProps & IFCheckbox) {
        super(props);
    }
    public render() {
        return (
            <Checkbox
                {...this.props}
                checked={this.props.field.value}
                onChange={(e) => {
                    this.props.onChange && this.props.onChange(e);
                    this.props.form.setFieldValue(
                        this.props.field.name,
                        e.target.checked
                    );
                    this.props.form.setFieldTouched(
                        this.props.field.name,
                        true
                    );
                }}
                aria-label={this.props.label}
            />
        );
    }
}
export type TransferProps<T> = FormikFieldProps & $TransferProps<T>

export function FTransfer<T extends TransferItem = TransferItem>({
    name,
    validate,
    fast,
    onChange,
    ...restProps
}: TransferProps<T>) {
    return (
        <Field name={name} validate={validate} fast={fast}>
            {({
                field: { value },
                form: { setFieldValue, setFieldTouched },
            }: FieldProps) => (
                <$Transfer<T>
                    targetKeys={value || []}
                    onChange={(targetKeys: any, direction: any, moveKeys: any) => {
                        setFieldValue(name, targetKeys)
                        setFieldTouched(name, true, false)
                        onChange && onChange(targetKeys, direction, moveKeys)
                    }}
                    {...restProps}
                />
            )}
        </Field>
    )
}

export default FTransfer


