import React, { createRef } from 'react';
import { EwdColumnProps, dateRenderer, dateTimeRenderer } from 'helper/GridHelper';
import { ReportClient, CsvReportClient, SieveModel as ClientSieveModel, ExportColumn, ExciseStampRegisterReportDto, CreateReportRequest } from 'services/GeneratedClient';
import { SieveModel } from 'layout/table/paginated/SieveModel';
import PaginatedTable from 'layout/table/paginated/PaginatedTable';
import { PageHeader } from 'layout/PageHeader';
import { inject } from 'mobx-react';
import { DictionaryStore } from 'dictionary/dictionaryStore';
import { GenerateCSVButton } from "components/GenerateCSVButton"
import { Moment } from 'moment';
import { GeneratePdfButton } from '../../components/GeneratePdfButton';
import getExciseStampRegisterReportColumns from './utils/getExciseStampRegisterReportColumns';

interface IProps {
    dictionaryStore?: DictionaryStore;
    exciseStampRegisterId?: number;
    dateFrom?: Moment;
    dateTo?: Moment;
}

interface IState {
    gridName: string;
    pageHeader: string;
    sieveModelUp?: SieveModel | undefined;
}

@inject("dictionaryStore")
export default class ExciseStampRegisterReportList extends React.PureComponent<IProps, IState>{
    constructor(props: IProps) {
        super(props);
        this.state = {
            gridName: "ExciseStampRegisterReportGrid",
            pageHeader: "RAPORT EWIDENCJI BANDEROL"
        }
    }

    private columns = getExciseStampRegisterReportColumns({ ...this.props });

    componentDidUpdate(prevProps: IProps) {
        if (prevProps.dateFrom !== this.props.dateFrom ||
            prevProps.dateTo !== this.props.dateTo ||
            prevProps.exciseStampRegisterId !== this.props.exciseStampRegisterId) {
            this.paginatedTable.current?.refresh();
        }
    }

    private getExportColumns(): ExportColumn[] {
        let visibleColumns: ExportColumn[] = this.paginatedTable.current?.getVisibleColumnsWithFilters().map(item =>
            new ExportColumn({
                key: item.key?.toString(),
                title: item.title?.toString(),
            }))!;
        return visibleColumns;
    }

    private getClientSieveModel(): ClientSieveModel {
        let { filters, sorts, page } = this.state.sieveModelUp!;
        return new ClientSieveModel({ filters: filters, sorts: sorts, page: page, pageSize: 1 })
    }

    private createReportPdfRequest(): CreateReportRequest {
        let parameters = new CreateReportRequest();
        parameters.sieveModel = this.getClientSieveModel();
        parameters.columns = this.getExportColumns();
        return parameters;
    }

    private paginatedTable = createRef<PaginatedTable<ExciseStampRegisterReportDto>>();

    public render() {
        return (
            <>
                <PageHeader title={this.state.pageHeader}
                    right={
                        this.props.exciseStampRegisterId
                        &&
                        <>
                            <GenerateCSVButton getFile={() => {
                                let { filters, sorts, page, totalRowCount } = this.state.sieveModelUp!;
                                return new CsvReportClient().getExciseStampRegisterReportCSV(filters, sorts, page, 25, this.props.exciseStampRegisterId ?? null, this.props.dateFrom!, this.props.dateTo!
                                    , totalRowCount!, Intl.DateTimeFormat().resolvedOptions().timeZone);
                            }} />
                            <GeneratePdfButton getFile={() => {
                                return new ReportClient().createExciseStampRegisterReportPdf(
                                    this.createReportPdfRequest(),
                                    this.props.exciseStampRegisterId ?? null,
                                    this.props.dateFrom!, this.props.dateTo!, this.state.pageHeader, Intl.DateTimeFormat().resolvedOptions().timeZone)
                            }} />
                        </>
                    }
                />
                <PaginatedTable<ExciseStampRegisterReportDto>
                    noScroll={false}
                    ref={this.paginatedTable}
                    gridName={this.state.gridName}
                    columns={this.columns}
                    columnsSelector={true}
                    getRowKey={(r: ExciseStampRegisterReportDto) => (r.id ?? "") + '_' + r.date.toString() + '_' + (r.registerId ?? "") + '_' + (r.sendIdentifier ?? "")}
                    getPagedResult={(sieve: SieveModel) => {
                        this.setState({ sieveModelUp: sieve });
                        return new ReportClient().getExciseStampRegisterReport(sieve.filters, sieve.sorts, sieve.page, sieve.pageSize, this.props.exciseStampRegisterId ?? null, this.props.dateFrom!, this.props.dateTo!,);
                    }}
                    scroll={{ y: 'calc(120vh - 590px)' }} />
            </>
        )
    }
};
