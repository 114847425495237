import React, { createRef } from 'react';
import { EwdColumnProps, dateRenderer } from 'helper/GridHelper';
import { DictionaryName, EwdPlaceLevelPermission, ExciseEnergyActionType, ExciseEnergyEntryClient, ExciseEnergyEntryListDto } from 'services/GeneratedClient';
import { inject } from 'mobx-react';
import { DictionaryStore } from 'dictionary/dictionaryStore';
import { SieveModel } from 'layout/table/paginated/SieveModel';
import PaginatedTable from 'layout/table/paginated/PaginatedTable';
import { Authorized } from 'authorization/Authorized';
import FadingTooltip from 'layout/FadingTooltip';
import TableButton from 'layout/table/tableButton/TableButton';
import { Link } from 'react-router-dom';
import { EditOutlined, SearchOutlined } from '@ant-design/icons';
import ExciseEnergyChangeToReadyButton from './actionButtons/ExciseEnergyChangeToReadyButton';
import ExciseEnergyChangeToEditedButton from './actionButtons/ExciseEnergyChangeToEditedButton';
import ExciseEnergyDeleteEntryButton from './actionButtons/ExciseEnergyDeleteEntryButton';
import { Moment } from 'moment';

interface IProps {
    dictionaryStore?: DictionaryStore;
    exciseEnergyRegisterId: number;
    date: Moment;
}

interface IState {
    gridName: string;
}

interface IState {
    gridName: string;
}

@inject("dictionaryStore")
export default class ExciseEnergyEntryDailyList extends React.PureComponent<IProps, IState>{
    constructor(props: IProps) {
        super(props);
        this.state = {
            gridName: "dailyExciseEnergyEntryListGrid"
        }
    }

    componentDidUpdate = (prevProps: IProps, prevState: IState) => {
        if (prevProps.date !== this.props.date) {
            this.paginatedTable.current?.refresh();
        }
    }

    refreshGrid = () => {
        this.paginatedTable.current?.refresh();
    }

    private columns: (EwdColumnProps<ExciseEnergyEntryListDto>)[] = [
        {
            key: "actions",
            render: (text: string, record: ExciseEnergyEntryListDto) => (
                <>
                    {record.id && <>
                        <Authorized placeLevelPermission={EwdPlaceLevelPermission.ExciseEnergyRegisterEntryDisplay} placeContext={record.placeContext}>
                            <FadingTooltip title="Podgląd" placement="top">
                                <Link to={`/ExciseEnergies/Entries/Preview/${record.id}`} >
                                    <TableButton gridName={this.state.gridName} recordId={record.id} icon={<SearchOutlined />} />
                                </Link>
                            </FadingTooltip>
                        </Authorized>
                        {record.actions?.includes(ExciseEnergyActionType.Editing) && <Authorized placeContext={record.placeContext} placeLevelPermission={EwdPlaceLevelPermission.ExciseEnergyRegisterEntryEditing}>
                            <FadingTooltip title="Redaguj" placement="top">
                                <Link to={`/ExciseEnergies/Entries/Edit/${record.id}`} >
                                    <TableButton gridName={this.state.gridName} recordId={record.id} icon={<EditOutlined />} />
                                </Link>
                            </FadingTooltip>
                        </Authorized>}
                        {record.actions?.includes(ExciseEnergyActionType.Confirming) && <Authorized placeContext={record.placeContext} placeLevelPermission={EwdPlaceLevelPermission.ExciseEnergyRegisterEntryEditing}>
                            <ExciseEnergyChangeToReadyButton
                                recordId={record.id}
                                onSuccess={this.refreshGrid}
                                onFailure={this.refreshGrid}
                            />
                        </Authorized>}
                        {record.actions?.includes(ExciseEnergyActionType.SwitchingToEdited) && <Authorized placeContext={record.placeContext} placeLevelPermission={EwdPlaceLevelPermission.ExciseEnergyRegisterEntryEditing}>
                            <ExciseEnergyChangeToEditedButton
                                recordId={record.id}
                                onSuccess={this.refreshGrid}
                                onFailure={this.refreshGrid}
                            />
                        </Authorized>}
                        {record.actions?.includes(ExciseEnergyActionType.Deleting) && <Authorized placeContext={record.placeContext} placeLevelPermission={EwdPlaceLevelPermission.ExciseEnergyRegisterEntryEditing}>
                            <ExciseEnergyDeleteEntryButton
                                recordId={record.id}
                                onSuccess={this.refreshGrid}
                                onFailure={this.refreshGrid}
                            />
                        </Authorized>}
                    </>}
                </>
            ),
            title: "Czynności",
            width: 140,
        },
        {
            dataIndex: "id",
            key: "Id",
            title: "Id",
            width: 80,
            filter: "equals",
        },
        {
            dataIndex: "date",
            key: "Date",
            title: "Data",
            render: (text: any, record: ExciseEnergyEntryListDto) => dateRenderer(record.date),
            width: 90,
        },
        {
            dataIndex: "paragraphOfRegulation",
            key: "ParagraphOfRegulation",
            title: "Paragraf",
        },
        {
            dataIndex: "invoiceNumber",
            key: "InvoiceNumber",
            title: "Nr faktury",
            filter: "contains",
        },
        {
            dataIndex: "date",
            key: "InvoiceDate",
            title: "Data faktury",
            render: (text: any, record: ExciseEnergyEntryListDto) => dateRenderer(record.invoiceDate),
            width: 90,
        },
        {
            dataIndex: "cnCode",
            key: "CnCode",
            title: "Kod CN",
            filter: "contains",
        },
        {
            dataIndex: "productName",
            key: "ProductName",
            title: "Nazwa towaru",
            filter: "contains",
        },
        {
            dataIndex: "incomeInExciseUnit",
            key: "IncomeInExciseUnit",
            title: "Przychód w jednostce rozliczenia akcyzy",
        },
        {
            dataIndex: "expenditureInExciseUnit",
            key: "ExpenditureInExciseUnit",
            title: "Rozchód w jednostce rozliczenia akcyzy",
        },
        {
            dataIndex: "exciseAmount",
            key: "ExciseAmount",
            title: "Kwota akcyzy",
        },
        {
            dataIndex: "comment",
            key: "Comment",
            title: "Komentarz",
        },
        {
            dataIndex: "status",
            key: "Status",
            title: "Status",
            render: (text: any, record: ExciseEnergyEntryListDto) => {
                return this.props.dictionaryStore!.getDisplay(DictionaryName.ExciseEnergyEntryStatus, record.status);
            },
        },
    ];

    private paginatedTable = createRef<PaginatedTable<ExciseEnergyEntryListDto>>();
    public render() {
        return (
            <>
                <PaginatedTable<ExciseEnergyEntryListDto>
                    ref={this.paginatedTable}
                    gridName={this.state.gridName}
                    columns={this.columns}
                    columnsSelector={true}
                    getRowKey={(r: ExciseEnergyEntryListDto) => r.id!.toString()}
                    getPagedResult={(sieve: SieveModel) => {
                        return new ExciseEnergyEntryClient().getDaily(sieve.filters, sieve.sorts, sieve.page, sieve.pageSize, this.props.exciseEnergyRegisterId, this.props.date.asUtc());
                    }}
                    scroll={{ y: 'calc(100vh - 470px)' }}
                />
            </>
        )
    }
};
