import { Alert, Col } from 'antd';
import { Card, DetailCell, RowWithBottomMargin } from 'components/StateDetailsComponents';
import { ajaxCatch } from 'helper/api';
import { CenteredSpin } from 'layout/CenteredSpin';
import React, { useEffect, useState } from 'react'
import { PlaceClient, PlaceDto, TraderDto } from 'services/GeneratedClient';

interface IProps {
    placeId: number | null
    horizontal?: boolean | undefined
}

export const PlaceDetails = (props: IProps) => {
    const [placeDto, setPlaceDto] = useState<PlaceDto>();
    const [traderDto, setTraderDto] = useState<TraderDto>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if (props.placeId) {
            setIsLoading(true);
            fetchPlaceDetails();
        }
    }, [props.placeId]);

    function fetchPlaceDetails() {
        ajaxCatch(() => new PlaceClient().getPlaceDetails(props.placeId!), response => {
            setPlaceDto(response?.placeDetails);
            setTraderDto(response?.traderDetails);
        }).finally(() => setIsLoading(false));
    }

    return (isLoading ? <CenteredSpin size="large" /> :
        <>
            <RowWithBottomMargin>
                <Col span={props.horizontal ? 12 : 24}>
                    <Card title="Szczegóły miejsca ewidencji">
                        <RowWithBottomMargin>
                            <DetailCell
                                label={"Identyfikator"}
                                value={placeDto?.id?.toString()} />
                            <DetailCell
                                label={"Nazwa miejsca"}
                                value={placeDto?.name + (placeDto?.isHistorical ? "(archiwalny)" : "")} />
                        </RowWithBottomMargin>
                        <RowWithBottomMargin>
                            <DetailCell
                                label={"Miejscowość"}
                                value={placeDto?.city} />
                            <DetailCell
                                label={"Kod Pocztowy"}
                                value={placeDto?.postcode} />
                        </RowWithBottomMargin>
                        <RowWithBottomMargin>
                            <DetailCell
                                label={"Ulica"}
                                value={placeDto?.streetName} />
                            <DetailCell
                                label={"Nr domu"}
                                value={placeDto?.streetNumber} />
                        </RowWithBottomMargin>
                        <RowWithBottomMargin>
                            <DetailCell
                                label={"Kod urzędu"}
                                value={placeDto?.officeReferenceNumber} />
                            <DetailCell
                                label={"Identyfikator miejsca"}
                                value={placeDto?.placeExciseNumber} />
                        </RowWithBottomMargin>
                    </Card>
                </Col>
                <Col span={props.horizontal ? 12 : 24}>
                    <Card title="Szczegóły podmiotu miejsca">
                        {traderDto ?
                            <>
                                <RowWithBottomMargin>
                                    <DetailCell
                                        label={"Identyfikator"}
                                        value={traderDto?.id?.toString()} />
                                    <DetailCell
                                        label={"Nazwa"}
                                        value={traderDto?.name} />
                                </RowWithBottomMargin>
                                <RowWithBottomMargin>
                                    <DetailCell
                                        label={"Miejscowość"}
                                        value={traderDto?.city} />
                                    <DetailCell
                                        label={"Kod pocztowy"}
                                        value={traderDto?.postalCode} />
                                </RowWithBottomMargin>
                                <RowWithBottomMargin>
                                    <DetailCell
                                        label={"Ulica"}
                                        value={traderDto?.street} />
                                    <DetailCell
                                        label={"Numer VAT"}
                                        value={traderDto?.vatNumber} />
                                </RowWithBottomMargin>
                                <RowWithBottomMargin>
                                    <DetailCell
                                        label={"Nr domu"}
                                        value={traderDto?.houseNumber} />
                                    <DetailCell
                                        label={"Nr lokalu"}
                                        value={traderDto?.flatNumber} />
                                </RowWithBottomMargin>
                            </> : <Alert
                                type="error"
                                showIcon
                                message="Brak jednoznacznego powiązania z podmiotem" />}
                    </Card>
                </Col>
            </RowWithBottomMargin>
        </>
    )
}
