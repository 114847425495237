import React, { createRef } from 'react';
import { EwdColumnProps, dateRenderer } from 'helper/GridHelper';
import { ActionType, CompanyLevelPermission, DictionaryName, EwdPlaceLevelPermission, ExciseEntryClient, ExciseEntryDto } from 'services/GeneratedClient';
import { inject } from 'mobx-react';
import { DictionaryStore } from 'dictionary/dictionaryStore';
import { SieveModel } from 'layout/table/paginated/SieveModel';
import PaginatedTable from 'layout/table/paginated/PaginatedTable';
import { Moment } from 'moment';
import { SearchOutlined, EditOutlined } from '@ant-design/icons';
import { Authorized } from 'authorization/Authorized';
import FadingTooltip from 'layout/FadingTooltip';
import TableButton from 'layout/table/tableButton/TableButton';
import { Link } from 'react-router-dom';
import ChangeToEditedButton from './current/ChangeToEditedButton';
import ChangeToReadyButton from './current/ChangeToReadyButton';
import DeleteEntryButton from './current/DeleteEntryButton';

interface IProps {
    dictionaryStore?: DictionaryStore;
    exciseRegisterId: number;
    isIncome: boolean;
    isExpenditure: boolean;
    isTransshipment: boolean;
    date: Moment;
}

interface IState {
    gridName: string;
    expandedRowKeys?: React.Key[]
}

interface ExciseEntryColumnProps {
    income: boolean;
    expenditure: boolean;
    transshipment: boolean;
}

@inject("dictionaryStore")
export default class DailyExciseEntryList extends React.PureComponent<IProps, IState>{
    constructor(props: IProps) {
        super(props);
        this.state = {
            gridName: this.getGridName(this.props.isIncome, this.props.isExpenditure, this.props.isTransshipment),
        }
    }

    getGridName = (isIncome: boolean, isExpenditure: boolean, isTransshipment: boolean) => `dailyExcise${isIncome ? "Income" : ""}${isExpenditure ? "Expenditure" : ""}${isTransshipment ? "Transshipment" : ""}EntriesList`

    componentDidUpdate = (prevProps: IProps, prevState: IState) => {
        const gridName = this.getGridName(this.props.isIncome, this.props.isExpenditure, this.props.isTransshipment);
        if (gridName != prevState.gridName) {
            this.setState({ gridName });
        }
        if (prevProps.date !== this.props.date) {
            this.paginatedTable.current?.refresh();
        }
    }

    refreshGrid = () => {
        this.paginatedTable.current?.refresh();
    }

    private columns: (ExciseEntryColumnProps & EwdColumnProps<ExciseEntryDto>)[] = [
        {
            key: "actions",
            render: (text: string, record: ExciseEntryDto) => (
                <>
                    {record.id && <>
                        <Authorized placeContext={record.placeContext} placeLevelPermission={EwdPlaceLevelPermission.ExciseRegisterEntryDisplay}>
                            <FadingTooltip title="Podgląd" placement="top">
                                <Link to={`/ExciseEntries/Preview/${record.id}`} >
                                    <TableButton gridName={this.state.gridName} recordId={record.id} icon={<SearchOutlined />} />
                                </Link>
                            </FadingTooltip>
                        </Authorized>
                        {record.actions?.includes(ActionType.Editing) && <Authorized placeContext={record.placeContext}
                            placeLevelPermission={EwdPlaceLevelPermission.ExciseRegisterEntryEditing}>
                            <FadingTooltip title="Redaguj" placement="top">
                                <Link to={`/ExciseRegisters/${this.props.exciseRegisterId}/ExciseEntries/Edit/${record.id}`} >
                                    <TableButton gridName={this.state.gridName} recordId={record.id} icon={<EditOutlined />} />
                                </Link>
                            </FadingTooltip>
                        </Authorized>}
                        {record.actions?.includes(ActionType.Confirming) && <Authorized placeContext={record.placeContext}
                            placeLevelPermission={EwdPlaceLevelPermission.ExciseRegisterEntryEditing}>
                            <ChangeToReadyButton
                                recordId={record.id}
                                onSuccess={this.refreshGrid}
                                onFailure={this.refreshGrid}
                            />
                        </Authorized>}
                        {record.actions?.includes(ActionType.SwitchingToEdited) && <Authorized placeContext={record.placeContext}
                            placeLevelPermission={EwdPlaceLevelPermission.ExciseRegisterEntryEditing}>
                            <ChangeToEditedButton
                                recordId={record.id}
                                onSuccess={this.refreshGrid}
                                onFailure={this.refreshGrid}
                            />
                        </Authorized>}
                        {record.actions?.includes(ActionType.Deleting) && <Authorized placeContext={record.placeContext}
                            placeLevelPermission={EwdPlaceLevelPermission.ExciseRegisterEntryEditing}>
                            <DeleteEntryButton
                                recordId={record.id}
                                onSuccess={this.refreshGrid}
                                onFailure={this.refreshGrid}
                            />
                        </Authorized>}
                    </>}
                </>
            ),
            title: "Czynności",
            income: true,
            expenditure: true,
            transshipment: true,
            width: 140,
        },
        {
            dataIndex: "id",
            key: "Id",
            title: "Id",
            income: true,
            expenditure: true,
            transshipment: true,
            width: 80,
            filter: "equals",
        },
        {
            dataIndex: "date",
            key: "Date",
            title: "Data",
            render: (text: any, record: ExciseEntryDto) => dateRenderer(record.date),
            income: true,
            expenditure: true,
            transshipment: true,
            width: 90,
        },
        {
            dataIndex: "ordinalNumber",
            key: "OrdinalNumber",
            title: "Lp.",
            income: true,
            expenditure: true,
            transshipment: true,
        },
        {
            dataIndex: "correctionOrdinalNumber",
            key: "CorrectionOrdinalNumber",
            title: "Lp. Korekty",
            income: true,
            expenditure: true,
            transshipment: true,
        },
        {
            dataIndex: "status",
            key: "Status",
            render: (text: any, record: ExciseEntryDto) => {
                return this.props.dictionaryStore!.getDisplay(DictionaryName.ExciseEntryStatus, record.status);
            },
            title: "Status",
            income: true,
            expenditure: true,
            transshipment: true,
        },
        {
            dataIndex: "paragraphOfRegulation",
            key: "ParagraphOfRegulation",
            title: "Paragraf",
            income: true,
            expenditure: true,
            transshipment: true,
        },
        {
            dataIndex: "supplierName",
            key: "SupplierName",
            title: "Dostawca",
            income: true,
            expenditure: false,
            transshipment: false,
            filter: "contains",
        },
        {
            dataIndex: "recipientName",
            key: "RecipientName",
            title: "Odbiorca",
            income: false,
            expenditure: true,
            transshipment: false,
            filter: "contains",
        },
        {
            dataIndex: "ownerName",
            key: "OwnerName",
            title: "Właściciel",
            income: false,
            expenditure: false,
            transshipment: true,
            filter: "contains",
        },
        {
            dataIndex: "documentNumber",
            key: "DocumentNumber",
            title: "Nr dokumentu",
            income: true,
            expenditure: true,
            transshipment: true,
            filter: "contains",
        },
        {
            dataIndex: "productNumber",
            key: "ProductNumber",
            title: "Nr pozycji dokumentu",
            income: true,
            expenditure: true,
            transshipment: true,
        },
        {
            dataIndex: "invoiceNumber",
            key: "InvoiceNumber",
            title: "Nr faktury",
            income: true,
            expenditure: true,
            transshipment: true,
            filter: "contains",
        },
        {
            dataIndex: "exciseProductCode",
            key: "ExciseProductCode",
            title: "Kod wyrobu akcyzowego",
            income: true,
            expenditure: true,
            transshipment: true,
            filter: "contains",
        },
        {
            dataIndex: "cnCode",
            key: "CnCode",
            title: "Kod CN",
            income: true,
            expenditure: true,
            transshipment: true,
            filter: "contains",
        },
        {
            dataIndex: "productName",
            key: "ProductName",
            title: "Nazwa towaru",
            income: true,
            expenditure: true,
            transshipment: true,
            filter: "contains",
        },
        {
            dataIndex: "productCode",
            key: "ProductCode",
            title: "Kod Towarowy",
            income: true,
            expenditure: true,
            transshipment: true,
            filter: "contains",
        },
        {
            dataIndex: "incomeInExciseUnit",
            key: "IncomeInExciseUnit",
            title: "Przychód w jednostce rozliczenia akcyzy",
            income: true,
            expenditure: false,
            transshipment: false,
        },
        {
            dataIndex: "incomeInInventoryUnit",
            key: "IncomeInInventoryUnit",
            title: "Przychód w jednostce technicznej",
            income: true,
            expenditure: false,
            transshipment: false,
        },
        {
            dataIndex: "expenditureInExciseUnit",
            key: "ExpenditureInExciseUnit",
            title: "Rozchód w jednostce rozliczenia akcyzy",
            income: false,
            expenditure: true,
            transshipment: false,
        },
        {
            dataIndex: "expenditureInInventoryUnit",
            key: "ExpenditureInInventoryUnit",
            title: "Rozchód w jednostce technicznej",
            income: false,
            expenditure: true,
            transshipment: false,
        },
        {
            dataIndex: "amountTransshipmentInExciseUnit",
            key: "AmountTransshipmentInExciseUnit",
            title: "Ilość przeładowana w jednostce rozliczenia akcyzy",
            income: false,
            expenditure: false,
            transshipment: true,
        },
        {
            dataIndex: "amountTransshipmentInTechnicalUnit",
            key: "AmountTransshipmentInTechnicalUnit",
            title: "Ilość przeładowana w jednostce technicznej",
            income: false,
            expenditure: false,
            transshipment: true,
        },
        {
            dataIndex: "unitConverter",
            key: "UnitConverter",
            title: "Przelicznik jednostek",
            income: true,
            expenditure: true,
            transshipment: true,
        },
        {
            dataIndex: "storedAmount",
            key: "StoredAmount",
            title: "Ilość magazynowana (stan po Zdarzeniu)",
            income: true,
            expenditure: true,
            transshipment: false,
        },
        {
            dataIndex: "methodOfConsumptionOfGoods",
            key: "MethodOfConsumptionOfGoods",
            title: "Sposób zużycia wyrobów akcyzowych",
            income: false,
            expenditure: true,
            transshipment: false,
        },
        {
            dataIndex: "methodOfProcessingOfGoods",
            key: "MethodOfProcessingOfGoods",
            title: "Sposób przetworzenia",
            income: false,
            expenditure: true,
            transshipment: false,
        },
        {
            dataIndex: "exciseAmount",
            key: "ExciseAmount",
            title: "Kwota akcyzy",
            income: true,
            expenditure: true,
            transshipment: false,
        },
    ];

    private paginatedTable = createRef<PaginatedTable<ExciseEntryDto>>();

    public render() {
        return (
            <>
                <PaginatedTable<ExciseEntryDto>
                    ref={this.paginatedTable}
                    gridName={this.state.gridName}
                    columns={this.columns.filter(x => (this.props.isIncome && x.income) || (this.props.isExpenditure && x.expenditure) || (this.props.isTransshipment && x.transshipment))}
                    columnsSelector={true}
                    getRowKey={(r: ExciseEntryDto) => r.id!.toString()}
                    getPagedResult={(sieve: SieveModel) => {
                        return new ExciseEntryClient().getDetailsForDay(sieve.filters, sieve.sorts, sieve.page, sieve.pageSize, 
                            this.props.isIncome, this.props.isExpenditure, this.props.isTransshipment, this.props.exciseRegisterId, this.props.date.asUtc());
                    }}
                    scroll={{ y: '38vh' }}
                />
            </>
        )
    }
};
