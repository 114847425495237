import React, { createRef } from "react";
import { PageHeader } from 'layout/PageHeader';
import PaginatedTable from "layout/table/paginated/PaginatedTable";
import { ActionType, DictionaryName, EwdPlaceLevelPermission, ExciseEntryClient, ExciseEntryDto } from "services/GeneratedClient";
import { SieveModel } from "layout/table/paginated/SieveModel";
import { dateRenderer, EwdColumnProps } from "helper/GridHelper";
import { DictionaryStore } from "dictionary/dictionaryStore";
import { inject } from "mobx-react";
import FadingTooltip from "layout/FadingTooltip";
import { Link } from "react-router-dom";
import TableButton from "layout/table/tableButton/TableButton";
import { SearchOutlined } from "@ant-design/icons";
import { GridStore } from "layout/table/paginated/GridStore";
import DeleteEntryButton from "exciseEntries/current/DeleteEntryButton";

interface IProps {
    dictionaryStore?: DictionaryStore;
    gridStore?: GridStore;
}

interface IState {
    gridName: string;
}

@inject("dictionaryStore", "gridStore")
export default class AwaitingExciseEntryList extends React.PureComponent<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            gridName: "awaitingExciseEntriesList"
        }
    }

    componentDidMount() {
        this.props.gridStore?.clearWithoutLastUsedRowId(this.state.gridName);
    }

    refreshGrid = () => {
        this.paginatedTable.current?.refresh();
    }

    private columns: (EwdColumnProps<ExciseEntryDto>)[] = [
        {
            key: "actions",
            render: (text: string, record: ExciseEntryDto) => (
                <>
                    {record.id && <>
                        <FadingTooltip title="Podgląd" placement="top">
                            <Link to={`/ExciseEntries/Awaiting/Preview/${record.id}`} >
                                <TableButton gridName={this.state.gridName} recordId={record.id} icon={<SearchOutlined />} />
                            </Link>
                        </FadingTooltip>
                        {record.actions?.includes(ActionType.Deleting) &&
                            <DeleteEntryButton
                                recordId={record.id}
                                isAwaiting
                                onSuccess={this.refreshGrid}
                                onFailure={this.refreshGrid}
                            />}
                    </>}
                </>
            ),
            title: "Czynności",
            width: 120,
        },
        {
            dataIndex: "id",
            key: "Id",
            title: "Id",
            sorter: true,
            filter: "equals",
            width: 80,
        },
        {
            dataIndex: "date",
            key: "Date",
            title: "Data",
            sorter: true,
            filter: "dateRange",
            render: (text: any, record: ExciseEntryDto) => dateRenderer(record.date),
            width: 90,
        },
        {
            dataIndex: "sourceIdentifier",
            key: "SourceIdentifier",
            title: "Identyfikator źródłowy",
            filter: "contains",
        },
        {
            dataIndex: "paragraphOfRegulation",
            key: "ParagraphOfRegulation",
            render: (text: any, record: ExciseEntryDto) => {
                return this.props.dictionaryStore!.getDisplay(DictionaryName.RegulationExciseEntry, record.paragraphOfRegulation);
            },
            title: "Paragraf",
        },
        {
            dataIndex: "status",
            key: "Status",
            render: (text: any, record: ExciseEntryDto) => {
                return this.props.dictionaryStore!.getDisplay(DictionaryName.ExciseEntryStatus, record.status);
            },
            title: "Status",
        },
        {
            dataIndex: "supplierIdentifier",
            key: "SupplierIdentifier",
            title: "Dostawca identyfikator",
            filter: "contains",
        },
        {
            dataIndex: "supplierName",
            key: "SupplierName",
            title: "Dostawca",
            filter: "contains",
        },
        {
            dataIndex: "recipientIdentifier",
            key: "RecipientIdentifier",
            title: "Odbiorca identyfikator",
            filter: "contains",
        },
        {
            dataIndex: "recipientName",
            key: "RecipientName",
            title: "Odbiorca",
            filter: "contains",
        },
        {
            dataIndex: "documentType",
            key: "DocumentType",
            title: "Typ dokumentu",
            filter: "contains",
        },
        {
            dataIndex: "documentNumber",
            key: "DocumentNumber",
            title: "Nr dokumentu",
            sorter: true,
            filter: "contains",
        },
        {
            dataIndex: "productNumber",
            key: "ProductNumber",
            title: "Nr pozycji dokumentu",
            filter: "equals",
        },
        {
            dataIndex: "invoiceNumber",
            key: "InvoiceNumber",
            title: "Nr faktury",
            filter: "contains",
        },
        {
            dataIndex: "invoicePosition",
            key: "InvoicePosition",
            title: "Nr pozycji faktury",
            filter: "equals",
        },
        {
            dataIndex: "typeOfLocalDocument",
            key: "TypeOfLocalDocument",
            title: "Rodzaj dokumentu lokalnego",
            filter: "contains",
        },
        {
            dataIndex: "localDocumentNumber",
            key: "LocalDocumentNumber",
            title: "Numer dokumentu lokalnego",
            filter: "contains",
        },
        {
            dataIndex: "localProductNumber",
            key: "LocalProductNumber",
            title: "Numer pozycji dokumentu lokalnego",
            filter: "equals",
        },
        {
            dataIndex: "productCode",
            key: "ProductCode",
            title: "Kod towaru",
            filter: "contains",
        },
        {
            dataIndex: "productName",
            key: "ProductName",
            title: "Nazwa towaru",
            filter: "contains",
        },
        {
            dataIndex: "cnCode",
            key: "CnCode",
            title: "Kod CN",
            sorter: true,
            filter: "contains",
        },
        {
            dataIndex: "exciseProductCode",
            key: "ExciseProductCode",
            title: "Kod wyrobu akcyzowego",
            filter: "contains",
        },
        {
            dataIndex: "incomeInExciseUnit",
            key: "IncomeInExciseUnit",
            title: "Przychód w jednostce rozliczenia akcyzy",
        },
        {
            dataIndex: "incomeInInventoryUnit",
            key: "IncomeInInventoryUnit",
            title: "Przychód w jednostce technicznej",
        },
        {
            dataIndex: "expenditureInExciseUnit",
            key: "ExpenditureInExciseUnit",
            title: "Rozchód w jednostce rozliczenia akcyzy",
        },
        {
            dataIndex: "expenditureInInventoryUnit",
            key: "ExpenditureInInventoryUnit",
            title: "Rozchód w jednostce technicznej",
        },
        {
            dataIndex: "unitConverter",
            key: "UnitConverter",
            title: "Przelicznik jednostek",
        },
        {
            dataIndex: "amountOfShortage",
            key: "AmountOfShortage",
            title: "Ubytki z dostawy / przemieszczenia",
        },
    ];

    private paginatedTable = createRef<PaginatedTable<ExciseEntryDto>>();
    public render() {
        return <>
            <PageHeader title={`ZDARZENIA DO PRZYPISANIA`} />
            <PaginatedTable<ExciseEntryDto>
                ref={this.paginatedTable}
                gridName={this.state.gridName}
                columns={this.columns}
                columnsSelector={true}
                getRowKey={(r: ExciseEntryDto) => r.id!.toString()}
                getPagedResult={(sieve: SieveModel) => {
                    return new ExciseEntryClient().getAwaiting(sieve.filters, sieve.sorts, sieve.page, sieve.pageSize);
                }}
                scroll={{ y: 'calc(100vh - 360px)' }}
            />
        </>
    }
}
