import React, { createRef } from 'react';
import { EwdColumnProps, dateRenderer, dateTimeRenderer, booleanRenderer, yesNoBooleanValueProvider } from 'helper/GridHelper';
import { ReportClient, CsvReportClient, ExciseRegisterReportDto, SieveModel as ClientSieveModel, ExportColumn, CreateReportRequest } from 'services/GeneratedClient';
import { SieveModel } from 'layout/table/paginated/SieveModel';
import PaginatedTable from 'layout/table/paginated/PaginatedTable';
import { PageHeader } from 'layout/PageHeader';
import { inject, observer } from 'mobx-react';
import { DictionaryStore } from 'dictionary/dictionaryStore';
import { GenerateCSVButton } from "components/GenerateCSVButton"
import { Moment } from 'moment';
import { GeneratePdfButton } from '../../components/GeneratePdfButton';
import { AccountDetailsStore } from 'account/AccountDetailsStore';
import getExciseRegisterReportGridName from './utils/getExciseRegisterReportGridName';
import getExciseRegisterReportColumns from './utils/getExciseRegisterReportColumns';

interface IProps {
    dictionaryStore?: DictionaryStore;
    exciseRegisterId?: number;
    isIncome: boolean;
    isExpenditure: boolean;
    dateFrom?: Moment;
    dateTo?: Moment;
    showContainers: boolean;
    hasControlOfNegativeStates: boolean;
    accountDetailsStore?: AccountDetailsStore;
    templateApplied: boolean;
}

interface IState {
    gridName: string;
    sieveModelUp?: SieveModel | undefined;
}


@inject("dictionaryStore")
@inject("accountDetailsStore")
@observer
export default class ExciseRegisterReportList extends React.PureComponent<IProps, IState>{
    constructor(props: IProps) {
        super(props);
        this.state = {
            gridName: getExciseRegisterReportGridName(props.isIncome, props.isExpenditure, this.props.templateApplied),
        }
    }

    private columns = getExciseRegisterReportColumns({ ...this.props });

    private getGridName = () => getExciseRegisterReportGridName(this.props.isIncome, this.props.isExpenditure, this.props.templateApplied);

    private getHeaderTitle = () => {
        if (this.props.isIncome && this.props.isExpenditure) {
            return `RAPORT PEŁNYCH EWIDENCJI AKCYZOWYCH`;
        }
        else if (this.props.isIncome) {
            return `RAPORT PRZYCHODÓW EWIDENCJI AKCYZOWYCH`;
        }
        else if (this.props.isExpenditure) {
            return `RAPORT ROZCHODÓW EWIDENCJI AKCYZOWYCH`;
        }
        else {
            return `RAPORT EWIDENCJI AKCYZOWYCH`;
        }
    }

    componentDidUpdate(prevProps: IProps) {
        this.setState({ gridName: this.getGridName() });
        if (this.props.showContainers) {
            this.showContainersColumns();
        }
        if (prevProps.dateFrom !== this.props.dateFrom
            || prevProps.dateTo !== this.props.dateTo
            || prevProps.exciseRegisterId !== this.props.exciseRegisterId
            || prevProps.showContainers !== this.props.showContainers) {
            this.paginatedTable.current?.refresh();
        }
        if (prevProps.hasControlOfNegativeStates !== this.props.hasControlOfNegativeStates) {
            this.checkIfRegisterHasControlOfNegativeStates();
        }
    }

    // Dodaje kolumny dotyczące kontenerów do listy wyświetlanych kolumn
    // Ma to działać niezależnie od zapisanych kolumn: jak ktoś je usunie z listy to przy ponownym włączeniu checkboxa znowu zostaną dodane
    // Móżna jeszcze dodać zapisywanie GridSettings do bazy, ale na razie nie znalazłem takiej potrzeby
    private showContainersColumns() {
        let gridSettings = (this.props.accountDetailsStore! as AccountDetailsStore).gridSettings![this.getGridName()];
        if (gridSettings && gridSettings.visibleColumnKeys) {
            this.columns.filter(c => c.containers).forEach((col) =>
                this.addIfNotExists(gridSettings.visibleColumnKeys, col.key?.toString())
            );
        }
    }

    // Dodaje do tablicy element jeśli go tam nie ma
    private addIfNotExists(array: string[] | undefined, item: string | undefined) {
        if (array && item && array.indexOf(item) === -1) {
            array.push(item);
        }
    }

    private getExportColumns(): ExportColumn[] {
        let visibleColumns: ExportColumn[] = this.paginatedTable.current?.getVisibleColumnsWithFilters().map(item =>
            new ExportColumn({
                key: item.key?.toString(),
                title: item.title?.toString(),
            }))!;
        return visibleColumns;
    }

    private getClientSieveModel(): ClientSieveModel {
        let { filters, sorts, page } = this.state.sieveModelUp!;
        return new ClientSieveModel({ filters: filters, sorts: sorts, page: page, pageSize: 1 })
    }

    private getCreateReportRequest(): CreateReportRequest {
        let parameters = new CreateReportRequest();
        parameters.sieveModel = this.getClientSieveModel();
        parameters.columns = this.getExportColumns();
        return parameters;
    }

    checkIfRegisterHasControlOfNegativeStates() {
        if (!this.props.hasControlOfNegativeStates)
            this.columns.find(x => x.dataIndex === "storedAmountInExciseUnit")!.title = "Rozchód narastająco";
        else
            this.columns.find(x => x.dataIndex === "storedAmountInExciseUnit")!.title = "Ilość magazynowana w jednostce rozliczenia akcyzy";
    }

    private paginatedTable = createRef<PaginatedTable<ExciseRegisterReportDto>>();

    public render() {
        return (
            <>
                <PageHeader title={this.getHeaderTitle()}
                    right={
                        this.props.exciseRegisterId
                        &&
                        <>
                            <GenerateCSVButton getFile={() => {
                                let { totalRowCount } = this.state.sieveModelUp!;
                                return new CsvReportClient().createExciseRegisterReportCSV(this.getCreateReportRequest(),
                                    this.props.isIncome, this.props.isExpenditure, this.props.exciseRegisterId ?? null,
                                    this.props.dateFrom!, this.props.dateTo!, totalRowCount!, Intl.DateTimeFormat().resolvedOptions().timeZone,
                                    this.props.showContainers)
                            }} />
                            <GeneratePdfButton getFile={() => {
                                return new ReportClient().createExciseRegisterReportPdf(this.getCreateReportRequest(),
                                    this.props.isIncome, this.props.isExpenditure, this.props.exciseRegisterId ?? null,
                                    this.props.dateFrom!, this.props.dateTo!, this.getHeaderTitle(), Intl.DateTimeFormat().resolvedOptions().timeZone,
                                    this.props.showContainers, this.props.hasControlOfNegativeStates)
                            }} />
                        </>
                    }
                />
                <PaginatedTable<ExciseRegisterReportDto>
                    noScroll={false}
                    ref={this.paginatedTable}
                    gridName={this.state.gridName}
                    columns={this.columns.filter(x =>
                        (x.income && x.income == this.props.isIncome)
                        || (x.expenditure && x.expenditure == this.props.isExpenditure)
                        || (this.props.showContainers && x.containers == this.props.showContainers))}
                    columnsSelector={true}
                    getRowKey={(r: ExciseRegisterReportDto) => (r.id ?? "") + '_' + r.date.toString() + '_' + (r.registerId ?? "") + '_' + (r.containerId ?? "") + '_' + (r.exciseRegisterStateId ?? "")}
                    getPagedResult={(sieve: SieveModel) => {
                        this.setState({ sieveModelUp: sieve });
                        return new ReportClient().getExciseRegisterReport(sieve.filters, sieve.sorts, sieve.page, sieve.pageSize, 
                            this.props.isIncome, this.props.isExpenditure, this.props.exciseRegisterId ?? null, this.props.dateFrom!, this.props.dateTo!, this.props.showContainers);
                    }}
                    scroll={{ y: 'calc(100vh - 590px)', x: undefined }} />
            </>
        )
    }
};
