import { Collapse, Modal } from "antd";
import React from "react";
import { SwaggerException } from "services/GeneratedClient";
import { GlobalUserManager } from "authentication/UserManager";

export function showFormIsInvalidModal() {
    Modal.warning({
        centered: true,
        maskClosable: true,
        title: 'Formularz zawiera błędy!',
    });
}

export function showMessageIsValidModal() {
    Modal.success({
        centered: true,
        maskClosable: true,
        title: 'Komunikat jest poprawny.',
    });
}

export function showSuccess(message: string) {
    Modal.success({
        centered: true,
        maskClosable: true,
        title: message,
    });
}

export function showWarning(message: string) {
    Modal.warn({
        centered: true,
        maskClosable: true,
        title: message,
    });
}

export function showErrorModal(message: string) {
    Modal.error({
        centered: true,
        title: message
    });
}

export function showNotExpectedErrorModal(error: SwaggerException) {
    if (error && error!.status === 403) {
        Modal.warning({
            centered: true,
            title: "Nie posiadasz wystarczających uprawnień"
        });
    } else if (error && error!.status === 400) {
        Modal.warning({
            centered: true,
            title: "Błędy walidacji",
            content: ((JSON.parse(error.response) as string[]).flat() as string[]).map((msg: string, index: number) => {
                return <p key={index}>{msg}</p>
            })
        });
        console.log(error.response);
    } else if (error && error!.status === 401) {
        GlobalUserManager.UserManager().signinSilent();
        Modal.warning({
            centered: true,
            title: "Wystąpił błąd, spróbuj ponownie"
        });
    } else {
        console.log(error);
        Modal.error({
            centered: true,
            title: 'Wystąpił niespodziewany błąd. Skontaktuj się z administratorem.',
        });
    }
}

export function showErrorListModal(title: string, errors: string[]){
    const customPanelStyle = {
        background: '#f7f7f7',
        borderRadius: 4,
        border: 0,
        overflow: 'hidden',
    };

    Modal.error({
        title: title,
        okText: "Zamknij",
        width: 450,
        content: (
            <Collapse bordered={false} style={{ marginTop: 24 }} defaultActiveKey={["1"]}>
                {errors && errors.length > 0 &&
                    <Collapse.Panel header="Błędy bezpieczeństwa" key="1" style={{ ...customPanelStyle, marginBottom: 4 }}>
                        {
                            errors!.map((e, i) => (
                                <p key={i}>{e}</p>
                            ))
                        }
                    </Collapse.Panel>
                }
            </Collapse>)
    });
}
