import React from "react";
import { MenuInfo } from 'rc-menu/lib/interface';
import { User } from "oidc-client";
import {
    DownOutlined,
    HomeOutlined,
    QuestionCircleOutlined,
    UserOutlined,
    WarningOutlined,
} from '@ant-design/icons';
import { Avatar, Button, Divider, Dropdown, Menu, Modal, Row } from "antd";
import { inject, observer } from "mobx-react";
import { GlobalUserManager } from "authentication/UserManager";
import { AccountDetailsStore } from "account/AccountDetailsStore";
import { HelpClient, SupportContactDto } from "services/GeneratedClient";
import { showNotExpectedErrorModal } from "./Modals";
import { SettingsStore } from "settings/settingsStore";
import { ajaxCatch, downloadFile } from "helper/api";
import CompanyLimitsLeft from "layout/CompanyLimitsLeft";
import { RegisterStore } from "register/RegisterStore";

interface IAccountProps {
    accountDetailsStore?: AccountDetailsStore;
    settingsStore?: SettingsStore;
    registerStore?: RegisterStore;
    onCompanyChange: () => void;
}

interface IState {
    user: User | undefined;
    contacts: SupportContactDto[] | undefined;
}


@inject("accountDetailsStore")
@inject("settingsStore")
@inject("registerStore")
@observer
class TopBar extends React.Component<IAccountProps, IState> {
    menu = (
        <Menu>
            <Menu.Item key="0">
                <a onClick={() => { GlobalUserManager.UserManager().signoutRedirect() }}>Wyloguj się</a>
            </Menu.Item>
        </Menu>
    );
    contacts: SupportContactDto[] = [];

    componentDidMount() {
        this.loadContactData();
    }

    loadContactData() {
        ajaxCatch(() =>
            new HelpClient().getContacts(),
            (resp: SupportContactDto[] | null) => {
                this.contacts = resp ?? []
            }
        );
    }

    handleCompanyMenuClick = (e: MenuInfo) => {
        let chosenCompany = parseInt(e.key);
        if (chosenCompany !== this.props.accountDetailsStore!.currentCompany!.id) {
            this.props.accountDetailsStore!.changeCurrentCompany(parseInt(e.key));
            this.props.registerStore!.setCompany(parseInt(e.key));
            this.props.registerStore!.refreshRegisters();
            this.props.onCompanyChange();
        }
    }

    companyMenu = () =>
        <Menu onClick={this.handleCompanyMenuClick}>
            {this.props.accountDetailsStore!.user!.companies && this.props.accountDetailsStore!.user!.companies.map((c) => (
                <Menu.Item key={c.id.toString()}>
                    {c.name}
                </Menu.Item>
            ))}
        </Menu>

    renderCompaniesDropdown = () => {

        const currentCompany = this.props.accountDetailsStore!.currentCompany;
        const currentCompanyName = currentCompany
            ? currentCompany.name
            : "";

        return currentCompanyName && <React.Fragment>
            {currentCompany && currentCompany.emergencyMode &&
                <span style={{ verticalAlign: "middle" }}>

                    <WarningOutlined style={{ color: "red" }} />
                    <span style={{ color: "red", marginRight: 16 }}>TRYB AWARYJNY</span>
                </span>
            }
            <HomeOutlined style={{ verticalAlign: "middle" }} />
            <span style={{ marginLeft: 4, marginRight: 16, display: "inline", verticalAlign: "middle" }}>

                <Dropdown overlay={this.companyMenu()}>
                    <a>{currentCompanyName}<DownOutlined /></a>
                </Dropdown>
            </span>
        </React.Fragment>;
    }

    downloadPDF(): any {
        new HelpClient().downloadPDF()
            .then((response) => {
                //Create a Blob from the PDF Stream
                const file = new Blob(
                    [response.data],
                    { type: 'application/pdf' });

                downloadFile(file, response.fileName!);
            })
            .catch((resp) => {
                showNotExpectedErrorModal(resp);
            });
    }

    helpModal(): any {
        Modal.info({
            title: "Pomoc techniczna",
            width: 550,
            content: <>
                <Row><label>Pomoc techniczna działa w dni robocze od godz. 9.00 do 17.00.</label></Row>
                <Divider className="divider-thin" />
                {this.contacts?.map((contact, i) => (
                    <Row key={i}>
                        <label>{`${contact.description}:`}</label> <span style={{ marginTop: 5, marginLeft: 3 }}>{contact.value}</span>
                    </Row>
                ))}
                <Divider className="divider-thin" />
                <Button type="default" onClick={() => this.downloadPDF()}>Pobierz podręcznik eWD</Button>
            </>,
        });
    }

    renderHelpMenu = () => {
        return (
            <React.Fragment>
                <a onClick={() => { this.helpModal() }}>
                    <QuestionCircleOutlined style={{ verticalAlign: "middle" }} />
                    <span style={{ marginLeft: 4, marginRight: 16, display: "inline", verticalAlign: "middle" }}>
                        Pomoc
                    </span>
                </a>
            </React.Fragment>
        );
    }

    public render() {
        return (
            <div className="top-bar" style={{ textAlign: "right" }}>
                {this.props.settingsStore?.settings?.environmentDisplay && <span className="environment">
                    {this.props.settingsStore.settings.environmentDisplay}
                </span>}
                {this.renderHelpMenu()}

                {this.renderCompaniesDropdown()}

                <CompanyLimitsLeft />

                <span className="account">
                    <span className="userName">
                        {(this.props.accountDetailsStore && this.props.accountDetailsStore.oidcUser)
                            ? this.props.accountDetailsStore.oidcUser.profile.name
                            : ""
                        }
                    </span>
                    <span>
                        <Dropdown overlay={this.menu} trigger={['click']}>
                            <a className="ant-dropdown-link" href="#">
                                <Avatar size="small" shape="square" icon={<UserOutlined />} className="account-avatar" />
                            </a>
                        </Dropdown>
                    </span>
                </span>
            </div>
        );
    }
}

export default TopBar;
