import React, { createRef } from 'react';
import { Link } from 'react-router-dom';
import { Authorized } from 'authorization/Authorized';
import { CorrectiveExciseEnergyEntryListDto, EwdPlaceLevelPermission, ExciseEnergyActionType, ExciseEnergyEntryClient, ExciseEnergyEntryListDto } from 'services/GeneratedClient';
import { SieveModel } from 'layout/table/paginated/SieveModel';
import PaginatedTable from 'layout/table/paginated/PaginatedTable';
import { GridStore } from 'layout/table/paginated/GridStore';
import { Moment } from 'moment';
import { inject } from 'mobx-react';
import { ExpandableConfig } from 'antd/lib/table/interface';
import FadingTooltip from 'layout/FadingTooltip';
import TableButton from 'layout/table/tableButton/TableButton';
import { DiffOutlined, DownOutlined, RightOutlined, SearchOutlined } from '@ant-design/icons';
import { dateRenderer, EwdColumnProps } from 'helper/GridHelper';

interface IProps {
    gridStore?: GridStore;
    exciseEnergyRegisterId: number
    dateFrom: Moment;
    dateTo: Moment;
}

interface IState {
    gridName: string;
    expandedRowKeys?: React.Key[]
}

@inject("gridStore")
export default class CompletedExciseEnergyEntryList extends React.PureComponent<IProps, IState>{

    constructor(props: IProps) {
        super(props);
        this.state = {
            gridName: 'completedExciseEnergyEntriesList',
        }
    }

    componentDidMount() {
        this.props.gridStore?.saveGridNameForLastUsedRowId(this.state.gridName);
    }

    expandableConfig(): ExpandableConfig<ExciseEnergyEntryListDto> {
        return {
            rowExpandable: (record: ExciseEnergyEntryListDto) => record.correctedEntries?.length !== 0,
            expandedRowRender: (record: ExciseEnergyEntryListDto) => {
                const columns: (EwdColumnProps<CorrectiveExciseEnergyEntryListDto>)[] = [
                    {
                        key: "actions",
                        render: (text: string, record: CorrectiveExciseEnergyEntryListDto) => (
                            <>
                                {record.id && <>
                                    <Authorized placeLevelPermission={EwdPlaceLevelPermission.ExciseEnergyRegisterEntryDisplay}
                                        placeContext={record.placeContext}>
                                        <FadingTooltip title="Podgląd" placement="top">
                                            <Link to={`/ExciseEnergies/Entries/Preview/${record.id}`} >
                                                <TableButton gridName={gridName} recordId={record.id} icon={<SearchOutlined />} />
                                            </Link>
                                        </FadingTooltip>
                                    </Authorized>
                                </>}
                            </>
                        ),
                    },
                    {
                        dataIndex: "id",
                        key: "Id",
                        title: "Id",
                    },
                    {
                        dataIndex: "date",
                        key: "Date",
                        title: "Data",
                        render: (text: any, record: CorrectiveExciseEnergyEntryListDto) => dateRenderer(record.date),
                        width: 90,
                    },
                    {
                        dataIndex: "correctionOrdinalNumber",
                        key: "CorrectionOrdinalNumber",
                        title: "Lp. Korekty",
                    },
                    {
                        dataIndex: "correctionReason",
                        key: "CorrectionReason",
                        title: "Przyczyna korekty",
                    },
                    {
                        dataIndex: "paragraphOfRegulation",
                        key: "ParagraphOfRegulation",
                        title: "Paragraf",
                    },
                    {
                        dataIndex: "invoiceNumber",
                        key: "InvoiceNumber",
                        title: "Nr faktury",
                    },
                    {
                        dataIndex: "date",
                        key: "InvoiceDate",
                        title: "Data faktury",
                        render: (text: any, record: CorrectiveExciseEnergyEntryListDto) => dateRenderer(record.invoiceDate),
                        width: 90,
                    },
                    {
                        dataIndex: "cnCode",
                        key: "CnCode",
                        title: "Kod CN",
                    },
                    {
                        dataIndex: "productName",
                        key: "ProductName",
                        title: "Nazwa towaru",
                    },
                    {
                        dataIndex: "incomeInExciseUnit",
                        key: "IncomeInExciseUnit",
                        title: "Przychód w jednostce rozliczenia akcyzy",
                    },
                    {
                        dataIndex: "expenditureInExciseUnit",
                        key: "ExpenditureInExciseUnit",
                        title: "Rozchód w jednostce rozliczenia akcyzy",
                    },
                    {
                        dataIndex: "exciseAmount",
                        key: "ExciseAmount",
                        title: "Kwota akcyzy",
                    },
                    {
                        dataIndex: "comment",
                        key: "Comment",
                        title: "Komentarz",
                    },
                ]
                const data: CorrectiveExciseEnergyEntryListDto[] | undefined = record.correctedEntries;
                const gridName = `corrective_${this.state.gridName}`;
                return <PaginatedTable<CorrectiveExciseEnergyEntryListDto>
                    gridName={gridName}
                    pagination={false}
                    columns={columns}
                    columnsSelector={true}
                    dataSource={data}
                    getRowKey={(r: CorrectiveExciseEnergyEntryListDto) => r.id!.toString()}
                    getPagedResult={() => new Promise((resolve, reject) => { resolve({ results: data }) })}
                />
            },
            expandedRowKeys: this.state.expandedRowKeys,
            expandIconColumnIndex: -1
        }
    };
    componentDidUpdate = (prevProps: IProps, prevState: IState) => {
        const gridName = this.state.gridName;
        if (gridName != prevState.gridName) {
            this.setState({ gridName });
        }
        if (prevProps.dateFrom !== this.props.dateFrom || prevProps.dateTo !== this.props.dateTo) {
            this.paginatedTable.current?.refresh();
        }
    }

    private columns: (EwdColumnProps<ExciseEnergyEntryListDto>)[] = [
        {
            key: "actions",
            render: (text: string, record: ExciseEnergyEntryListDto) => (
                <>
                    {record.id && <>
                        <Authorized placeLevelPermission={EwdPlaceLevelPermission.ExciseEnergyRegisterEntryDisplay}
                            placeContext={record.placeContext}>
                            <FadingTooltip title="Podgląd" placement="top">
                                <Link to={`/ExciseEnergies/Entries/Preview/${record.id}`} >
                                    <TableButton gridName={this.state.gridName} recordId={record.id} icon={<SearchOutlined />} />
                                </Link>
                            </FadingTooltip>
                        </Authorized></>}
                    {record.actions?.includes(ExciseEnergyActionType.Correcting) && !record.isSupplyEntry &&
                        <Authorized placeLevelPermission={EwdPlaceLevelPermission.ExciseEnergyRegisterEntryCorrecting}
                            placeContext={record.placeContext}>
                            <FadingTooltip title="Korekta" placement="top">
                                <Link to={`/ExciseEnergies/Entries/Correct/${record.id}`} >
                                    <TableButton gridName={this.state.gridName} recordId={record.id} icon={<DiffOutlined />} />
                                </Link>
                            </FadingTooltip>
                        </Authorized>}
                    {record.correctedEntries?.length !== 0 && <>
                        {this.state.expandedRowKeys && this.state.expandedRowKeys.includes(record.id!.toString())
                            ? <FadingTooltip title="Ukryj korekty">
                                <TableButton type="default" icon={<DownOutlined />} onClick={() => {
                                    const remove = this.state.expandedRowKeys!.indexOf(record.id!.toString());
                                    this.setState({
                                        expandedRowKeys: (this.state.expandedRowKeys || []).filter((_, i) => i !== remove)
                                    })
                                }}
                                />
                            </FadingTooltip>
                            : <FadingTooltip title="Pokaż korekty">
                                <TableButton type="default" icon={<RightOutlined />} onClick={() => {
                                    this.setState({
                                        expandedRowKeys: [...(this.state.expandedRowKeys || []), record.id!.toString()]
                                    })
                                }}
                                />
                            </FadingTooltip>
                        }
                    </>}
                </>
            ),
            title: "Czynności",
        },
        {
            dataIndex: "id",
            key: "Id",
            title: "Id",
            filter: "equals",
        },
        {
            dataIndex: "date",
            key: "Date",
            title: "Data",
            render: (text: any, record: ExciseEnergyEntryListDto) => dateRenderer(record.date),
            width: 90,
            filter: "dateRange",
        },
        {
            dataIndex: "ordinalNumber",
            key: "OrdinalNumber",
            title: "Lp.",
            filter: "equals",
        },
        {
            dataIndex: "correctionOrdinalNumber",
            key: "CorrectionOrdinalNumber",
            title: "Lp. Korekty",
            filter: "equals",
        },
        {
            dataIndex: "paragraphOfRegulation",
            key: "ParagraphOfRegulation",
            title: "Paragraf",
        },
        {
            dataIndex: "invoiceNumber",
            key: "InvoiceNumber",
            title: "Nr faktury",
        },
        {
            dataIndex: "date",
            key: "InvoiceDate",
            title: "Data faktury",
            render: (text: any, record: ExciseEnergyEntryListDto) => dateRenderer(record.invoiceDate),
            width: 90,
        },
        {
            dataIndex: "cnCode",
            key: "CnCode",
            title: "Kod CN",
        },
        {
            dataIndex: "productName",
            key: "ProductName",
            title: "Nazwa towaru",
        },
        {
            dataIndex: "incomeInExciseUnit",
            key: "IncomeInExciseUnit",
            title: "Przychód w jednostce rozliczenia akcyzy",
        },
        {
            dataIndex: "expenditureInExciseUnit",
            key: "ExpenditureInExciseUnit",
            title: "Rozchód w jednostce rozliczenia akcyzy",
        },
        {
            dataIndex: "exciseAmount",
            key: "ExciseAmount",
            title: "Kwota akcyzy",
        },
        {
            dataIndex: "comment",
            key: "Comment",
            title: "Komentarz",
        },
    ];

    private paginatedTable = createRef<PaginatedTable<ExciseEnergyEntryListDto>>();

    public render() {
        return (
            <>
                <PaginatedTable<ExciseEnergyEntryListDto>
                    ref={this.paginatedTable}
                    gridName={this.state.gridName}
                    columns={this.columns}
                    columnsSelector={true}
                    expandable={this.expandableConfig()}
                    getRowKey={(r: ExciseEnergyEntryListDto) => r.id!.toString()}
                    getPagedResult={(sieve: SieveModel) => {
                        return new ExciseEnergyEntryClient().getCompleted(sieve.filters, sieve.sorts, sieve.page, sieve.pageSize, 
                            this.props.exciseEnergyRegisterId, this.props.dateFrom, this.props.dateTo);
                    }}
                    scroll={{ y: 'calc(100vh - 550px)' }}
                />
            </>
        )
    }
};
