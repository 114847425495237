import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import "core-js/stable";
import 'regenerator-runtime/runtime';
// import 'url-polyfill';
import App from "./App";

import { ConfigProvider } from "antd";
import pl_PL from "antd/lib/locale-provider/pl_PL";
import * as React from "react";
import * as ReactDOM from "react-dom";
import "moment/locale/pl";
import "moment/locale/en-gb";
// import { SettingsClient, PublicSettingsDto, SwaggerException } from "./services/GeneratedClient";
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'mobx-react'
import "./antd-variables.less";
import "./index.less";
import "./forms.less";
import moment, { Moment } from "moment";
// import { GridStore } from "./paginatedSadTable/GridStore";
import { setLocale } from 'yup';
import { AccountDetailsStore } from 'account/AccountDetailsStore';
import { CenteredSpin } from './layout/CenteredSpin';
import { configure } from 'mobx';
import { SettingsClient, PublicSettingsDto, SwaggerException } from './services/GeneratedClient';
import { GlobalUserManager } from 'authentication/UserManager';
import { SettingsStore } from 'settings/settingsStore';
import { DictionaryStore } from 'dictionary/dictionaryStore';
import { GridStore } from 'layout/table/paginated/GridStore';
import { RegisterStore } from 'register/RegisterStore';
import { RegulationStore } from 'regulations/RegulationStore';
import { AlertsStore } from 'alerts/AlertsStore';
import { RegisterDetailsStore } from 'registerDetails/RegisterDetailsStore';
import { FormChangeStore } from 'formChange/FormChangeStore';

const settingsStore = new SettingsStore();
const accountDetailsStore = new AccountDetailsStore(settingsStore);
const dictionaryStore = new DictionaryStore();
const registerStore = new RegisterStore();
const gridStore = new GridStore();
const regulationStore = new RegulationStore();
const alertsStore = new AlertsStore();
const registerDetailsStore = new RegisterDetailsStore();
const formChangeStore = new FormChangeStore();

var jsFetch = window.fetch;
window.fetch = (input: RequestInfo | URL, init?: RequestInit | undefined) => {

    if (typeof input === 'string') {
        let url = new URL(input);
        let params = new URLSearchParams(url.search.slice(1));

        let currentCompanyId = accountDetailsStore.currentCompany && accountDetailsStore.currentCompany.id;
        if (currentCompanyId) {
            params.append('currentCompanyId', currentCompanyId.toString());
        }

        params.append('q', moment().format("x"));
        url.search = '?' + params.toString();
        input = url.href;
    }

    return jsFetch(input, init);
}

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");
// register(); // można włączyć  - cachowanie

ReactDOM.render(
    <CenteredSpin size={'large'} />, rootElement
);

new SettingsClient().getSettings()
    .then((settings: PublicSettingsDto | null) => {
        setCurrentCompany();
        GlobalUserManager.Init(settings!.authorityUrl!);
        settingsStore.setSettings(settings!);

        StartApp(settings!);
    })
    .catch((error: SwaggerException) => {
        console.log("Wystąpił błąd podczas pobierania ustawień", error);
        ReactDOM.render(<div>Wystąpił błąd podczas pobierania ustawień.</div>, rootElement);
    })

configure({
    enforceActions: "always"
});

function StartApp(settings: PublicSettingsDto) {
    moment.locale("pl");
    ReactDOM.render(
        <ConfigProvider locale={pl_PL}>
            <BrowserRouter basename={baseUrl!} >
                <Provider
                    accountDetailsStore={accountDetailsStore}
                    dictionaryStore={dictionaryStore}
                    gridStore={gridStore}
                    registerStore={registerStore}
                    regulationStore={regulationStore}
                    alertsStore={alertsStore}
                    settingsStore={settingsStore}
                    registerDetailsStore={registerDetailsStore}
                    formChangeStore={formChangeStore}
                >
                    <App settings={settings} />
                </Provider>
            </BrowserRouter>
        </ConfigProvider>, rootElement);
}

function setCurrentCompany() {
    var urlString = window.location.href;
    var url = new URL(urlString);
    var currentCompany = url.searchParams.get("companyId");
    if (currentCompany) {
        localStorage.setItem("companyId", currentCompany);
    }
}
