import { ExciseEntryFormDto } from "services/GeneratedClient";
import { nameof } from "utils/Nameof";

export const directions = {
    outgoing: "Outgoing",
    incoming: "Incoming",
    transshipment: "Transshipment"
}

export const generalInformationFieldNames = {
    paragraphOfRegulation: nameof<ExciseEntryFormDto>("paragraphOfRegulation"),
    direction: nameof<ExciseEntryFormDto>("direction"),
};

export const goodFormFieldNames = {
    productName: nameof<ExciseEntryFormDto>("productName"),
    productCode: nameof<ExciseEntryFormDto>("productCode"),
    exciseProductCode: nameof<ExciseEntryFormDto>("exciseProductCode"),
    cnCode: nameof<ExciseEntryFormDto>("cnCode"),
    fiscalMark: nameof<ExciseEntryFormDto>("fiscalMark"),
    notColouredAndMarkedFuelOils: nameof<ExciseEntryFormDto>("notColouredAndMarkedFuelOils"),
    incomeInExciseUnit: nameof<ExciseEntryFormDto>("incomeInExciseUnit"),
    expenditureInExciseUnit: nameof<ExciseEntryFormDto>("expenditureInExciseUnit"),
    incomeInInventoryUnit: nameof<ExciseEntryFormDto>("incomeInInventoryUnit"),
    expenditureInInventoryUnit: nameof<ExciseEntryFormDto>("expenditureInInventoryUnit"),
    unitConverter: nameof<ExciseEntryFormDto>("unitConverter"),
    cnCodeOfDriedTabacco: nameof<ExciseEntryFormDto>("cnCodeOfDriedTabacco"),
    netWeight: nameof<ExciseEntryFormDto>("netWeight"),
    alcoholicStrength: nameof<ExciseEntryFormDto>("alcoholicStrength"),
    degreePlato: nameof<ExciseEntryFormDto>("degreePlato"),
    fuelType: nameof<ExciseEntryFormDto>("fuelType"),
    maxRetailPrice: nameof<ExciseEntryFormDto>("maxRetailPrice"),
    sizeOfProducer: nameof<ExciseEntryFormDto>("sizeOfProducer"),
    biofuelContentQuality: nameof<ExciseEntryFormDto>("biofuelContentQuality"),
    amountOfShortage: nameof<ExciseEntryFormDto>("amountOfShortage"),
}

export const additionalDataFormFieldNames = {
    date: nameof<ExciseEntryFormDto>("date"),
    ordinalNumber: nameof<ExciseEntryFormDto>("ordinalNumber"),
    sourceIdentifier: nameof<ExciseEntryFormDto>("sourceIdentifier"),
    containerIdFrom: nameof<ExciseEntryFormDto>("containerIdFrom"),
    containerIdTo: nameof<ExciseEntryFormDto>("containerIdTo"),
}

export const fieldsAlwaysDisabled = [additionalDataFormFieldNames.date];

export const nonCorrectableFields = [
    generalInformationFieldNames.paragraphOfRegulation,
    generalInformationFieldNames.direction,
    goodFormFieldNames.cnCode,
    additionalDataFormFieldNames.date,
    additionalDataFormFieldNames.ordinalNumber,
    additionalDataFormFieldNames.sourceIdentifier,
    goodFormFieldNames.exciseProductCode,
    additionalDataFormFieldNames.containerIdFrom,
    additionalDataFormFieldNames.containerIdTo,
];

export const incomeAndExpenditureFields = [
    goodFormFieldNames.incomeInExciseUnit,
    goodFormFieldNames.incomeInInventoryUnit,
    goodFormFieldNames.expenditureInExciseUnit,
    goodFormFieldNames.expenditureInInventoryUnit
];

export const containerWithProductCodeFields = [
    additionalDataFormFieldNames.containerIdTo,
    additionalDataFormFieldNames.containerIdFrom,
    goodFormFieldNames.productCode
];

export const defaultContainers = {
    notSpecified: "Nie określony"
}

export const regulationParagraphs = {
    shortageParagraph: 'Ubytki',
    R10_4: "10.4",
    R10_5: "10.5",
    R10_6: "10.6",
    R12_2: "12.2"
}