import React, { createRef } from 'react';
import { EwdColumnProps } from 'helper/GridHelper';
import { CompanyLevelPermission, PlaceClient, PlaceDto } from 'services/GeneratedClient';
import { Button, Modal, Popconfirm } from 'antd';
import { Link } from 'react-router-dom';
import PaginatedTable from 'layout/table/paginated/PaginatedTable';
import { SieveModel } from 'layout/table/paginated/SieveModel';
import { PageHeader } from 'layout/PageHeader';
import { inject } from 'mobx-react';
import { DictionaryStore } from 'dictionary/dictionaryStore';
import BottomActionButtons from 'layout/ActionButtons/BottomActionButtons';
import { DeleteOutlined, DropboxOutlined, EyeInvisibleOutlined, EyeOutlined, SearchOutlined } from '@ant-design/icons';
import { ajaxByUser } from 'helper/api';
import FadingTooltip from 'layout/FadingTooltip';
import TableButton from 'layout/table/tableButton/TableButton';
import { GridStore } from 'layout/table/paginated/GridStore';
import { Authorized } from 'authorization/Authorized';
import { PlaceDetails } from './PlaceDetails';
import { RegisterStore } from 'register/RegisterStore';

interface IProps {
    dictionaryStore?: DictionaryStore;
    gridStore?: GridStore;
    registerStore?: RegisterStore;
}

interface IState {
    gridName: string;
    detailsVisible: boolean,
    placeId: number | null,
}

@inject("dictionaryStore")
@inject("gridStore")
@inject("registerStore")
export default class PlaceList extends React.PureComponent<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            gridName: "placeList",
            detailsVisible: false,
            placeId: null
        }
    }

    componentDidMount() {
        this.props.gridStore?.clearWithoutLastUsedRowId(this.state.gridName);
        this.props.gridStore?.saveGridNameForLastUsedRowId(this.state.gridName);
    }

    getDeletePromptMessage(record: PlaceDto): string {
        let message = `Czy na pewno chcesz usunąć miejsce o nazwie ${record.name}? Operacji nie można wycofać.`;
        if (record.hasUserPlacePermissions) {
            message += " Uwaga: Miejsce ma przypisane uprawnienia dla użytkowników.";
        }
        return message;
    }

    private columns: EwdColumnProps<PlaceDto>[] = [
        {
            key: "actions",
            render: (text: string, record: PlaceDto) => (
                <>
                    <FadingTooltip title={record.isActive ? "Dezaktywuj" : "Aktywuj"}>
                        <Popconfirm placement="bottomRight" title={<text>Czy na pewno chcesz {record.isActive ? "dezaktywować" : "aktywować"} miejsce {record.name}?</text>} okText="Tak" cancelText="Nie"
                            onConfirm={() => {
                                ajaxByUser(record.isActive ? "Dezaktywowano miejsce" : "Aktywowano miejsce", () =>
                                    new PlaceClient().switchIsActive(record.id!).then(() => {
                                        this.refresh();
                                        this.props.registerStore?.refreshRegisters();
                                    }));
                            }}>
                            <TableButton disabled={record.isHistorical} icon={record.isActive ? <EyeInvisibleOutlined /> : <EyeOutlined />} />
                        </Popconfirm>
                    </FadingTooltip>
                    <FadingTooltip title={"Zbiorniki"}>
                        <Link to={`/Admin/Containers/${record.id}`}>
                            <TableButton gridName={this.state.gridName} recordId={record.id} icon={<DropboxOutlined />} />
                        </Link>
                    </FadingTooltip>
                    <Authorized companyLevelPermission={CompanyLevelPermission.EwdAdministration}>
                        <FadingTooltip title={"Pokaż szczegóły ewidencji"}>
                            <TableButton icon={<SearchOutlined />} onClick={() => { this.setState({ placeId: record.id, detailsVisible: true }); }} />
                        </FadingTooltip>
                    </Authorized>
                    {
                        record.canDelete &&
                        <Authorized companyLevelPermission={CompanyLevelPermission.EwdAdministration}>
                            <FadingTooltip title={"Usuń nieprzypisane miejsce"}>
                                <Popconfirm placement="bottomRight" title={<text>{this.getDeletePromptMessage(record)}</text>}
                                    okText="Tak" cancelText="Nie"
                                    onConfirm={() => {
                                        ajaxByUser(`Miejsce o nazwie ${record.name} zostało poprawnie usunięte.`, () =>
                                            new PlaceClient().deleteUnassignedPlace(record.id!).then(() => {
                                                this.refresh();
                                            }));
                                    }}>
                                    <TableButton icon={<DeleteOutlined />} />
                                </Popconfirm>
                            </FadingTooltip>
                        </Authorized>
                    }
                </>
            ),
            width: 150,
            title: "Czynności"
        },
        {
            dataIndex: "id",
            key: "Id",
            title: "Id",
            sorter: true,
            filter: "equals",
        },
        {
            dataIndex: "placeExciseNumber",
            key: "PlaceExciseNumber",
            title: "Identyfikator miejsca",
            filter: "contains",
        },
        {
            dataIndex: "name",
            key: "Name",
            title: "Nazwa",
            filter: "contains",
        },
        {
            dataIndex: "deliveryPlaceTraderName",
            key: "TraderName",
            title: "Nazwa podmiotu",
            filter: "contains",
        },
        {
            dataIndex: "streetName",
            key: "StreetName",
            title: "Ulica",
            filter: "contains",
        },
        {
            dataIndex: "streetNumber",
            key: "StreetNumber",
            title: "Nr domu",
            filter: "equals",
        },
        {
            dataIndex: "postcode",
            key: "Postcode",
            title: "Kod pocztowy",
            filter: "equals",
        },
        {
            dataIndex: "city",
            key: "City",
            title: "Miejscowość",
            filter: "contains",
        },
        {
            dataIndex: "officeReferenceNumber",
            key: "OfficeReferenceNumber",
            title: "Kod urzędu",
            filter: "contains",
        },
        {
            dataIndex: "isActive",
            key: "IsActive",
            title: "Czy aktywne",
            render: (text: any, record: PlaceDto) => record.isActive ? "TAK" : "NIE",
        },
    ];

    private refresh() {
        this.paginatedTable.current?.refresh();
    }

    private paginatedTable = createRef<PaginatedTable<PlaceDto>>();

    public render() {
        return (
            <>
                <Modal
                    title="Podgląd szczegółów miejsca"
                    visible={this.state.detailsVisible}
                    onCancel={() => this.setState({ detailsVisible: false })}
                    footer={[
                        <Button key="back" onClick={() => this.setState({ detailsVisible: false })}>
                            Zamknij
                        </Button>
                    ]}>
                    <PlaceDetails placeId={this.state.placeId} />
                </Modal>
                <PageHeader title={`MIEJSCA EWIDENCJI AKCYZOWYCH`} />
                <PaginatedTable<PlaceDto>
                    ref={this.paginatedTable}
                    gridName={this.state.gridName}
                    columns={this.columns}
                    columnsSelector={true}
                    getRowKey={(r: PlaceDto) => r.id!.toString()}
                    getPagedResult={(sieve: SieveModel) => {
                        return new PlaceClient().getAll(sieve.filters, sieve.sorts, sieve.page, sieve.pageSize);
                    }} />
            </>
        )
    }
};
